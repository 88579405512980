<template>
  <div class="ModifyOrArchiveFacture">
    <CModal
      :show.sync="isFactureModified"
      :no-close-on-backdrop="true"
      title="Facture modifiée"
      size="lg"
      color="dark"
    >
      <p class="text-center" style="font-size: 1.2em; font-weight: bold;">
        Votre facture a bien été modifiée 👍
      </p>
      <p>
        Vous avez bien modifié votre facture. Vous pouvez continuer les modifications ou retourner à la liste des factures de votre cabinet.
      </p>
      <template #footer>
        <CButton @click="isFactureModified = false" color="primary">Continuer les modifications</CButton>
        <CButton @click="$router.push('/manager-dashboard-factures/')" color="dark">Retour à la liste des factures</CButton>
      </template>
    </CModal>
    <CModal
      :show.sync="isFactureArchived"
      :no-close-on-backdrop="true"
      title="Facture archivée"
      size="lg"
      color="dark"
    >
      <p class="text-center" style="font-size: 1.2em; font-weight: bold;">
        ⛔ Votre facture a bien été archivé ⛔
      </p>
      <p>
        Cette facture n'apparaîtra plus dans la liste des factures de votre cabinet. Vous pourrez toujours y accéder en vous rendant dans la rubrique
        "Archives - Factures archivées".
      </p>
      <template #footer>
        <CButton @click="$router.push('/manager-dashboard-factures/')" color="primary">Retour à la liste des factures</CButton>
        <CButton @click="isFactureArchived = false" color="dark">Continuer les modifications</CButton>
      </template>
    </CModal>

    <CModal
      :show.sync="isFactureSent"
      :no-close-on-backdrop="true"
      title="Facture envoyée"
      size="lg"
      color="dark"
    >
      <p class="text-center" style="font-size: 1.2em; font-weight: bold;">
        Votre facture a bien été envoyée 👍
      </p>
      <p>
        Votre facture a bien été envoyée à votre client.<br>
        <small> Si celui-ci ne la reçoit pas, n'oubliez pas de lui conseiller de vérifier dans ses spams</small>
      </p>
      <template #footer>
        <CButton @click="isFactureSent = false" color="primary">Fermer</CButton>
      </template>
    </CModal>

    <CModal
      :show.sync="isOnlinePaiementAdded"
      :no-close-on-backdrop="true"
      title="Lien de paiement créé"
      size="lg"
      color="dark"
    >
      <p class="text-center" style="font-size: 1.2em; font-weight: bold;">
        Votre lien de paiement en ligne a bien été créé 👍
      </p>
      <p>
        Vous avez bien créé un lien de paiement en ligne pour votre client. Il vous suffit de transmettre à votre client le lien ci dessous pour que celui-ci
        puisse directement payer le montant indiqué en ligne.
      </p>
      <div class="form-control d-flex align-items-center" style="padding-right: 0px !important;">
          <span>{{vueBaseUrl + '/pages/paiement-en-ligne/' + createdOnlinePaiement.random_url}}</span>
          <span class="btn btn-dark text-white ml-auto" @click.stop.prevent="copyCodeToClipBoard(vueBaseUrl + '/pages/paiement-en-ligne/' + createdOnlinePaiement.random_url)">
            Copier
          </span>
      </div>

      <template #footer>
        <CButton @click="isOnlinePaiementAdded = false" color="primary">Fermer</CButton>
      </template>
    </CModal>

    <CModal
      :show.sync="isContenuCreatedFromTasks"
      :no-close-on-backdrop="true"
      title="Item de facturation créé"
      size="lg"
      color="dark"
    >
      <p class="text-center" style="font-size: 1.2em; font-weight: bold;">
        Votre item de facturation a bien été créé 👍
      </p>
      <p>
        Vous avez bien créé un nouvel item de facturation à partir de votre reporting.
        Vous pouvez retrouver votre item dans l'onglet "Contenu de votre facture", le modifier ou le supprimer.
      </p>
      <template #footer>
        <CButton @click="isContenuCreatedFromTasks = false" color="primary">Fermer</CButton>
      </template>
    </CModal>

    <CModal
      :show.sync="isOnlinePaiementDeleted"
      :no-close-on-backdrop="true"
      title="Facture archivée"
      size="lg"
      color="dark"
    >
      <p class="text-center" style="font-size: 1.2em; font-weight: bold;">
        ⛔ Votre lien de paiement en ligne a bien été révoqué ⛔
      </p>
      <p>
        Le lien de paiement a bien été révoqué. Votre client ne pourra plus accéder à ce lien pour payer en ligne.
      </p>
      <template #footer>
        <CButton @click="isOnlinePaiementDeleted = false" color="dark">Fermer</CButton>
      </template>
    </CModal>


    <!-- MODIFY UNTE FACTURES -->
    <CModal
      :show.sync="isFactureDuplicating"
      :no-close-on-backdrop="true"
      title="Dupliquer cette facture"
      size="lg"
      color="dark"
    >
      <p>
        Vous pouvez dupliquer cette facture. Le numéro de facture sera automatiquement incrémenté, la date de facturation automatiquement mise à jour
        et le contenu de votre nouvelle facture restera le même.
      </p>
      <p class="text-danger">
        Attention, les paiement associés ne seront pas dupliqués. Vous serez automatiquement
        redirigé vers la page de votre nouvelle facture.
      </p>

      <CRow class="mt-4">
        <CCol class="text-center">
          <CButton
            :color="duplicateFactureButtonStyle"
            shape="pill" block class="px-4"
            :disabled="duplicateFactureInProcess"
            @click='duplicateFacture'>
            <CSpinner size="sm" label="update task spinner" v-if="duplicateFactureInProcess"></CSpinner>
            {{ duplicateFactureButtonText }}
          </CButton>
        </CCol>
      </CRow>

      <template #footer>
        <CButton @click="isFactureDuplicating = false" color="dark">Annuler</CButton>
      </template>
    </CModal>

    <!-- SEND FACTURE BY EMAIL -->
    <CModal
      :show.sync="isSendingFactureByEmail"
      :no-close-on-backdrop="true"
      title="Envoyer votre facture par email"
      size="lg"
      color="dark"
    >
      <p>
        Vous pouvez directement envoyer votre facture par email à votre client 💌
      </p>

      <CForm>

        <CInput
          v-model="sendFactureClientEmail" type="email"
          label="Email de votre client"
          placeholder="Email de votre client*"
          @input="$v.sendFactureClientEmail.$touch()"
          :isValid="$v.sendFactureClientEmail.$dirty ? !$v.sendFactureClientEmail.$error : null"
          invalid-feedback="Veuillez entrer un email au bon format"
        >
        </CInput>

        <CTextarea
          placeholder="Message personnalisé"
          label="Message personnalisé (facultatif)"
          type="text"
          max="500"
          rows="5"
          v-model="sendFactureCustomizedMessage"
          @input="$v.sendFactureCustomizedMessage.$touch()"
          :isValid="$v.sendFactureCustomizedMessage.$dirty ? !$v.sendFactureCustomizedMessage.$error : null">
        </CTextarea>

        <CInput
          v-model="sendFactureAnswerEmail" type="email"
          label="Email sur lequel votre client peut répondre"
          placeholder="Email de réponse*"
          @input="$v.sendFactureAnswerEmail.$touch()"
          :isValid="$v.sendFactureAnswerEmail.$dirty ? !$v.sendFactureAnswerEmail.$error : null"
          invalid-feedback="Veuillez entrer un email au bon format"
        >
        </CInput>

        <CRow class="mt-4">
          <CCol class="text-center">
            <CButton
              :color="sendFactureEmailButtonStyle"
              shape="pill" block class="px-4"
              :disabled="$v.sendFactureClientEmail.$invalid || $v.sendFactureCustomizedMessage.$invalid || $v.sendFactureAnswerEmail.$invalid || sendFactureEmailInProcess"
              @click='sendFactureEmail'>
              {{ sendFactureEmailButtonText }}
            </CButton>
          </CCol>
        </CRow>
      </CForm>

      <template #footer>
        <CButton @click="isSendingFactureByEmail = false" color="dark">Annuler</CButton>
      </template>
    </CModal>

    <!-- MODIFY UNTE FACTURES -->
    <CModal
      :show.sync="isUniteFacturesModifying"
      :no-close-on-backdrop="true"
      title="Modifier votre item"
      size="lg"
      color="dark"
    >
      <CForm>

        <CInput
          v-model="modifyUniteFacturesDate" type="date"
          label="Date"
          placeholder="AAAA-MM-DD"
          @input="$v.modifyUniteFacturesDate.$touch()"
          :isValid="$v.modifyUniteFacturesDate.$dirty ? !$v.modifyUniteFacturesDate.$error : null"
          invalid-feedback="Veuillez entrer votre date au bon format (dd/mm/aaaa ou aaaa-mm-dd)"
        >
          <template #description>
            <div v-if="$browserDetect.isIE || $browserDetect.isSafari" class="text-info">
              <small>Vous utilisez Internet Explorer ou Safari, veuillez entrer votre
              date au format AAAA-MM-DD (Par exemple 1975-11-03)</small>
            </div>
            <small class="text-danger" v-if="!$v.modifyUniteFacturesDate.validDate">Veuillez entrer votre date au bon format (dd/mm/aaaa ou aaaa-mm-dd)</small>
          </template>
        </CInput>

        <CTextarea
          placeholder="Description"
          label="Description"
          type="text"
          max="1000"
          rows="5"
          v-model="modifyUniteFacturesDescription"
          @input="$v.modifyUniteFacturesDescription.$touch()"
          :isValid="$v.modifyUniteFacturesDescription.$dirty ? !$v.modifyUniteFacturesDescription.$error : null">
        </CTextarea>


        <CRow>
          <CCol sm="6">
            <CInput
              placeholder="Quantité"
              label="Quantité"
              type="number"
              v-model="modifyUniteFacturesQuantite"
              @input="$v.modifyUniteFacturesQuantite.$touch()"
              :isValid="$v.modifyUniteFacturesQuantite.$dirty ? !$v.modifyUniteFacturesQuantite.$error : null"
              min="0" max="100000000" step=".01" @blur="modifyUniteFacturesQuantite = parseFloat(modifyUniteFacturesQuantite).toFixed(2)">
            </CInput>
          </CCol>
          <CCol sm="6">
            <CInput
              placeholder="Prix unitaire"
              label="Prix unitaire (en € HT)"
              type="number"
              v-model="modifyUniteFacturesPrixUnitaire"
              @input="$v.modifyUniteFacturesPrixUnitaire.$touch()"
              :isValid="$v.modifyUniteFacturesPrixUnitaire.$dirty ? !$v.modifyUniteFacturesPrixUnitaire.$error : null"
              min="-10000000" max="100000000" step=".01" @blur="modifyUniteFacturesPrixUnitaire = parseFloat(modifyUniteFacturesPrixUnitaire).toFixed(2)">
            </CInput>
          </CCol>
        </CRow>

        <CInput
          placeholder="Remise HT"
          label="Remise tarifaire (en € HT)"
          type="number"
          v-model="modifyUniteFacturesRemiseHT"
          @input="$v.modifyUniteFacturesRemiseHT.$touch()"
          :isValid="$v.modifyUniteFacturesRemiseHT.$dirty ? !$v.modifyUniteFacturesRemiseHT.$error : null"
          min="0" max="100000000" step=".01" @blur="modifyUniteFacturesRemiseHT = parseFloat(modifyUniteFacturesRemiseHT).toFixed(2)">
        </CInput>

        <CInput
          placeholder="TVA (en %)"
          label="Taux de TVA en %"
          type="number"
          v-model="modifyUniteFacturesTVA"
          @input="$v.modifyUniteFacturesTVA.$touch()"
          :isValid="$v.modifyUniteFacturesTVA.$dirty ? !$v.modifyUniteFacturesTVA.$error : null"
          min="0" max="100000000" step=".1" @blur="modifyUniteFacturesTVA = parseFloat(modifyUniteFacturesTVA).toFixed(1)">
          <template #append-content>%</template>
        </CInput>

        <CRow class="mt-4">
          <CCol class="text-center">
            <CButton
              :color="modifyUniteFacturesButtonStyle"
              shape="pill" block class="px-4"
              :disabled="$v.modifyUniteFacturesDate.$invalid || $v.modifyUniteFacturesDescription.$invalid || $v.modifyUniteFacturesQuantite.$invalid || $v.modifyUniteFacturesPrixUnitaire.$invalid || $v.modifyUniteFacturesRemiseHT.$invalid || $v.modifyUniteFacturesTVA$invalid || modifyUniteFacturesInProcess"
              @click='modifyUniteFactures'>
              <CSpinner size="sm" label="update task spinner" v-if="modifyUniteFacturesInProcess"></CSpinner>
              {{ modifyUniteFacturesButtonText }}
            </CButton>
          </CCol>


        </CRow>
      </CForm>

      <template #footer>
        <CButton @click="isUniteFacturesModifying = false" color="dark">Annuler</CButton>
      </template>
    </CModal>


    <!-- USE TASK TO GENERATE UNITE FACTURES -->
    <CModal
      :show.sync="isChoosingTaskToGenerateUniteFacture"
      :no-close-on-backdrop="true"
      title="Générer votre item de facturation à partir de votre reporting"
      size="xl"
      color="dark"
    >
      <CRow>
        <CCol>
          <p>
            <strong style="font-size: 1.2em;">Choisissez les tâches pour générer automatiquement des items de facturation</strong>
          </p>

          <CRow>
            <CCol xl="5">
              <CInput
                label="Date de début"
                type="date"
                :horizontal='true'
                v-model="filterTasksStartDate">
              </CInput>
            </CCol>
            <CCol xl="5">
              <CInput
                label="Date de fin"
                type="date"
                :horizontal='true'
                v-model="filterTasksEndDate">
              </CInput>
            </CCol>
            <CCol xl="2">
              <CButton
                color="primary"
                variant="outline"
                shape="pill"
                block
                @click="getAllTasksForFacturation()">
              Filtrer par date
            </CButton>
            </CCol>
          </CRow>

          <CRow class="mt-2 mt-xl-0">
            <CCol>
              <strong v-if="isNotBilled=='true'">
                Liste des tâches <u>pas encore facturées</u> entre le
                {{$dayjs(filterTasksStartDate).format('DD/MM/YYYY')}} et le
                {{$dayjs(filterTasksEndDate).format('DD/MM/YYYY')}}
              </strong>
              <strong v-if="isNotBilled=='false'">
                Liste des tâches <u>déjà facturées</u> entre le
                {{$dayjs(filterTasksStartDate).format('DD/MM/YYYY')}} et le
                {{$dayjs(filterTasksEndDate).format('DD/MM/YYYY')}}
              </strong>
            </CCol>
          </CRow>

          <CRow class="mt-2">
            <CCol>
              <CButton
                v-if="isNotBilled=='true'"
                color="primary"
                variant="outline"
                shape="pill"
                @click="isNotBilled='false';getAllTasksForFacturation()">
                Voir les tâches déjà facturées
              </CButton>
              <CButton
                v-if="isNotBilled=='false'"
                color="primary"
                variant="outline"
                shape="pill"
                @click="isNotBilled='true';getAllTasksForFacturation()">
                Voir les tâches non marquées comme facturées
              </CButton>
            </CCol>
            <CCol class="text-right">
              <router-link v-if="userGroupContains(['ADMIN'])"  to="/administration/manage-collaborateurs">
                Modifier le taux horaire des collaborateurs
              </router-link>
            </CCol>
          </CRow>

          <CRow class="mt-2" v-if="isNotBilled=='false'">
            <CCol>
              <strong class="text-danger">
                Attention, ces tâches ont déjà été utilisées pour générer des items de facturation
              </strong>
            </CCol>
          </CRow>

          <CDataTable
            items-per-page-select
            :items-per-page="50"
            pagination
            sorter
            :items="tasks"
            :fields="tasksRenderFields"
            :noItemsView='{ noResults: "Aucune tâche trouvée selon vos critères", noItems: "Aucune tâche trouvée selon vos critères" }'
          >
            <template #actions="{item}">
              <td>
                <CInputCheckbox
                  :custom="true"
                  :inline="true"
                  @click="checkTaskBox(item)"
                />
              </td>
            </template>
            <template #collaborateur_full_name="{item}">
              <td>
               {{item.collaborateur_full_name}}<br>
               <small v-if="tauxChosenForUniteFactureFromTask == 'tjh'">{{item.collaborateur_taux_horaire}} € HT par heure</small>
               <small v-if="tauxChosenForUniteFactureFromTask == 'tjm'">{{item.collaborateur_taux_horaire*8}} € HT par jour</small>
              </td>
            </template>
            <template #date="{item}">
              <td>
               <span>{{$dayjs(item.date).format('DD/MM/YYYY')}}</span>
              </td>
            </template>
            <template #duree="{item}">
              <td>
               <span>{{item.hours}} heures <span v-if="item.minutes > 0"> et {{item.minutes}} minutes</span></span>
              </td>
            </template>
            <template #description="{item}">
              <td style="white-space: pre-line;">
               <small>{{item.description}}</small>
              </td>
            </template>

          </CDataTable>

          <p>
            <strong style="font-size: 1.2em;">Aperçu de vos items de facturation générés</strong><br>
            <strong class="text-dark"> Pas d'inquiétude, vous pourrez toujours modifier ces items quand ils seront générés 🙂</strong>
          </p>
          <CRow>
            <CCol class="text-right">
              <CInputRadioGroup
                label="Taux choisi : "
                :checked.sync="tauxChosenForUniteFactureFromTask"
                :custom="true"
                :inline="true"
                :options='tauxChosenForUniteFactureFromTasksArrayForm'
              />
            </CCol>
          </CRow>
          <span class="text-white">{{chosenTasks}}</span>
          <CRow style="color: #848484; font-weight: 600;">
            <CCol sm="2">
              Date
            </CCol>
            <CCol sm="4">
              Description
            </CCol>
            <CCol sm="2">
              <span v-if="tauxChosenForUniteFactureFromTask == 'tjh'">
                Quantité (horaire)
              </span>
              <span v-if="tauxChosenForUniteFactureFromTask == 'tjm'">
                Quantité (jours)
              </span>
            </CCol>
            <CCol sm="2">
              Prix unitaire HT
            </CCol>
            <CCol sm="2">
              Prix total HT
            </CCol>
          </CRow>
          <CRow v-for="(item, tauxHoraire) in fromTaskUniteFactureDict" :key="tauxHoraire">
            <CCol sm="2">
              {{$dayjs().format('YYYY-MM-DD')}}
            </CCol>
            <CCol sm="4">
              <pre>{{item.descriptions.join("\n")}}</pre>
            </CCol>
            <CCol sm="2">
              <span v-if="tauxChosenForUniteFactureFromTask == 'tjh'">
                {{timeToDecimal(item.hours, item.minutes)}}
              </span>
              <span v-if="tauxChosenForUniteFactureFromTask == 'tjm'">
                {{parseFloat(timeToDecimal(item.hours, item.minutes)/8).toFixed(2)}}
              </span>
            </CCol>
            <CCol sm="2">
              <span v-if="tauxChosenForUniteFactureFromTask == 'tjh'">
                {{tauxHoraire}} € HT
              </span>
              <span v-if="tauxChosenForUniteFactureFromTask == 'tjm'">
                {{tauxHoraire*8}} € HT
              </span>
            </CCol>
            <CCol sm="2">
              {{timeToDecimal(item.hours, item.minutes)*tauxHoraire}} € HT
            </CCol>
          </CRow>

          <CButton
            color="primary"
            shape="pill" block class="mt-3 px-4"
            :disabled="chosenTasks.length == 0"
            @click='addMultipleUnitesFactureFromTasks'>
              Générer ces items de facturation et marquer les tâches choisies comme 'facturées'
          </CButton>
        </CCol>
      </CRow>



      <template #footer>
        <CButton @click="isChoosingTaskToGenerateUniteFacture = false" color="dark">Annuler</CButton>
      </template>
    </CModal>


    <!-- MODIFY PAIEMENT -->
    <CModal
      :show.sync="isPaiementModifying"
      :no-close-on-backdrop="true"
      title="Modifier votre paiement"
      size="lg"
      color="dark"
    >
      <CForm>
        <CInput
          v-model="modifyPaiementDate" type="date"
          label="Date"
          placeholder="AAAA-MM-DD"
          @input="$v.modifyPaiementDate.$touch()"
          :isValid="$v.modifyPaiementDate.$dirty ? !$v.modifyPaiementDate.$error : null"
          invalid-feedback="Veuillez entrer votre date au bon format (dd/mm/aaaa ou aaaa-mm-dd)"
        >
          <template #description>
            <div v-if="$browserDetect.isIE || $browserDetect.isSafari" class="text-info">
              <small>Vous utilisez Internet Explorer ou Safari, veuillez entrer votre
              date au format AAAA-MM-DD (Par exemple 1975-11-03)</small>
            </div>
            <small class="text-danger" v-if="!$v.modifyPaiementDate.validDate">Veuillez entrer votre date au bon format (dd/mm/aaaa ou aaaa-mm-dd)</small>
          </template>
        </CInput>

        <CSelect
          label="Modalités de paiement"
          :value.sync="modifyPaiementModalites"
          :options="modalitesPaiementArrayOptions"
          @input="$v.modifyPaiementModalites.$touch()"
          :isValid="$v.modifyPaiementModalites.$dirty ? !$v.modifyPaiementModalites.$error : null"
          custom
        />


        <CInput
          placeholder="Montant"
          label="Montant payé (TTC en €)"
          type="number"
          v-model="modifyPaiementMontantTTC"
          @input="$v.modifyPaiementMontantTTC.$touch()"
          :isValid="$v.modifyPaiementMontantTTC.$dirty ? !$v.modifyPaiementMontantTTC.$error : null"
          min="0" max="100000000" step=".01" @blur="modifyPaiementMontantTTC = parseFloat(modifyPaiementMontantTTC).toFixed(2)">
        </CInput>


        <CRow class="mt-4">
          <CCol class="text-center">
            <CButton
              :color="modifyPaiementButtonStyle"
              shape="pill" block class="px-4"
              :disabled="$v.modifyPaiementDate.$invalid || $v.modifyPaiementModalites.$invalid || $v.modifyPaiementMontantTTC.$invalid || modifyPaiementInProcess"
              @click='modifyPaiement'>
              <CSpinner size="sm" label="update task spinner" v-if="modifyPaiementInProcess"></CSpinner>
              {{ modifyPaiementButtonText }}
            </CButton>
          </CCol>
        </CRow>
      </CForm>

      <template #footer>
        <CButton @click="isPaiementModifying = false" color="dark">Annuler</CButton>
      </template>
    </CModal>



    <!-- ADD ONLINE PAIEMENT -->
    <CModal
      :show.sync="isOnlinePaiementAdding"
      :no-close-on-backdrop="true"
      title="Créer un lien de paiement en ligne"
      size="lg"
      color="dark"
    >
      <CForm>

        <CInput
          placeholder="Montant en €"
          label="Montant"
          type="number"
          v-model="newOnlinePaiement.montant"
          @input="$v.newOnlinePaiement.montant.$touch()"
          :isValid="$v.newOnlinePaiement.montant.$dirty ? !$v.newOnlinePaiement.montant.$error && newOnlinePaiement.montant <= leftToPay : null"
          min="0" max="100000000" step=".01" @blur="newOnlinePaiement.montant = parseFloat(newOnlinePaiement.montant).toFixed(2)">
        </CInput>



        <!--<CRow class="mb-3">
          <CCol sm="5">
            Voulez-vous programmer une relance automatique ?
          </CCol>
          <CCol sm="7">
            <CInputRadioGroup
              disabled
              :checked.sync="newOnlinePaiement.is_relance"
              :custom="true"
              :inline="true"
              :options='booleanArrayForm'
            />
          </CCol>
        </CRow>-->

        <CSelect
          v-if="newOnlinePaiement.is_relance == 'true'"
          label="Fréquence de relance"
          :value.sync="newOnlinePaiement.relance_frequence"
          :options="relanceFrequenceArrayOptions"
          @input="$v.newOnlinePaiement.relance_frequence.$touch()"
          :isValid="$v.newOnlinePaiement.relance_frequence.$dirty ? !$v.newOnlinePaiement.relance_frequence.$error : null"
          custom
        />

        <CRow class="mt-4">
          <CCol class="text-center">

            <CButton
              color="outline-primary"
              shape="pill" block class="px-4"
              :disabled="$v.newOnlinePaiement.montant.$invalid || $v.newOnlinePaiement.is_relance.$invalid || $v.newOnlinePaiement.relance_frequence.$invalid || newOnlinePaiement.montant > leftToPay || addOnlinePaiementInProcess"
              @click='addOnlinePaiement'>
              Créer votre lien de paiement en ligne
            </CButton>

          </CCol>
        </CRow>

        <span v-if="newOnlinePaiement.montant > leftToPay" class="text-danger">
          Vous ne pouvez demander un montant plus élevé que le reste à payer
        </span>
      </CForm>

      <template #footer>
        <CButton @click="isOnlinePaiementAdding = false" color="dark">Annuler</CButton>
      </template>
    </CModal>

    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="true"
      color="black">
    </loading>

    <CToaster :autohide="2000">
      <template v-for="toast in copiedToasts">
        <CToast
          :key="'toast' + toast"
          :show="true"
        >
        <template #default>
          <strong class="text-success">Copié 👍</strong>
        </template>
        </CToast>
      </template>
    </CToaster>

    <CRow>
      <CCol sm="6">
        <h1>
          Facture #{{facture.facture_number}} - {{facture.mission.client.name}}  <span v-if="isAvoir">- <u>AVOIR</u></span>
        </h1>
      </CCol>
      <CCol class="text-right align-self-center" sm="6">
        <CButton
          @click="isFactureDuplicating = true"
          size="sm"
          shape="pill"
          color="outline-dark">
            <CIcon name="cil-clone"/> Dupliquer cette facture
        </CButton>
      </CCol>
    </CRow>

    <CCard class="mt-3">
      <CCardBody>
        <div v-if="!isAvoir">
          <CRow v-if="isRetard && parseFloat(totalTTC).toFixed(2) != parseFloat(totalPaid).toFixed(2)" class="mb-3" style="font-size: 1.2em;">
            <CCol>
              <strong class="text-danger">⛔  Facture en retard</strong>
            </CCol>
          </CRow>
          <CRow v-if="parseFloat(totalTTC).toFixed(2) == parseFloat(totalPaid).toFixed(2) && parseFloat(totalTTC) != 0" class="mb-3" style="font-size: 1.2em;">
            <CCol>
              <strong class="text-success"> 🥳 Facture payée</strong>
            </CCol>
          </CRow>
        </div>

        <CRow>
          <CCol lg="3">
            <span> # Facture <span v-if="isAvoir">- <u>AVOIR</u></span> : </span> <strong> {{facture.facture_number}}</strong><br>
            <span> Client : </span> <strong> {{facture.mission.client.name}} </strong><br>
            <span> Mission : </span> <strong> {{facture.mission.name}}</strong><br>
          </CCol>
          <CCol v-if="!isAvoir" lg="9">
            <CRow class="mt-2 mt-lg-0">
              <CCol class="text-lg-right text-center" sm="4">
                <span> MONTANT HT A PAYER </span><br>
                <strong style="font-size: 2em;"> {{formatThousandSeparatorNumber(parseFloat(parseFloat(sousTotalHT).toFixed(2) - parseFloat(remiseTotaleHT).toFixed(2)).toFixed(2))}} €</strong>
                <strong v-if="isAvoir" class="text-danger">
                  <br>Attention, votre montant total est négatif, cette facture sera donc considérée comme un avoir lors du téléchargement
                </strong>
              </CCol>

              <CCol class="text-lg-right text-center" sm="4">
                <span> MONTANT TTC A PAYER </span><br>
                <strong style="font-size: 2em;"> {{formatThousandSeparatorNumber(parseFloat(totalTTC).toFixed(2))}} €</strong>
              </CCol>

              <CCol class="text-lg-right text-center" sm="4">
                <span> RESTE A PAYER </span><br>
                <strong style="font-size: 2em;"> {{formatThousandSeparatorNumber(parseFloat(parseFloat(totalTTC).toFixed(2) - parseFloat(totalPaid).toFixed(2)).toFixed(2))}} €</strong>
              </CCol>
            </CRow>
          </CCol>

          <CCol v-else lg="9">
            <CRow class="mt-2 mt-lg-0">
              <CCol class="text-lg-right text-center" sm="6">
                <span> MONTANT HT D'AVOIR </span><br>
                <strong style="font-size: 2em;"> {{formatThousandSeparatorNumber(parseFloat(parseFloat(sousTotalHT).toFixed(2) - parseFloat(remiseTotaleHT).toFixed(2)).toFixed(2))}} €</strong>
              </CCol>
              <CCol class="text-lg-right text-center" sm="6">
                <span> MONTANT TTC D'AVOIR </span><br>
                <strong style="font-size: 2em;"> {{formatThousandSeparatorNumber(parseFloat(totalTTC).toFixed(2))}} €</strong>
              </CCol>
            </CRow>
            <strong v-if="isAvoir" class="text-danger">
              <br>Attention, votre montant total est négatif, cette facture sera donc considérée comme un avoir lors du téléchargement
            </strong>
          </CCol>

        </CRow>
        <CRow v-if="parseFloat(sousTotalHT) == 0" class="mt-2">
          <CCol class="text-right">
            <strong class="text-danger">Veuillez renseigner des items de facturation</strong>
          </CCol>
        </CRow>

        <div v-if="parseFloat(sousTotalHT) != 0">
          <CRow  class="mt-4">
            <CCol v-if="!facture.has_been_sent" md="4" class="align-self-center">
              <CInputCheckbox
                  label="Marquer votre facture comme envoyée au client"
                  :custom="true"
                  name="hasBeenSentFactureCheckbox"
                  :inline="true"
                  @click="markFactureAsSent()"
                />
            </CCol>
            <CCol v-else md="4" class="align-self-center">
              <strong>💌 <em>Facture envoyée au client</em> </strong>
            </CCol>

            <CCol md="8" class="mt-2 mt-md-0 text-md-right align-self-center">
              <CButton shape="pill" color="outline-primary" @click="downloadFacture()">
                <CIcon name="cil-cloud-download"/>Télécharger votre facture en pdf
              </CButton>
              <br class="d-sm-block d-md-none">
              <CButton class="ml-0 ml-md-2 mt-2 mt-md-0" shape="pill" color="outline-primary" @click="isSendingFactureByEmail=true"> <CIcon name="cil-send" /> Envoyer votre facture par email </CButton>
            </CCol>
          </CRow>

          <div v-if="onlinePaiements.length == 0 && !isAvoir">
            <CRow v-if="companyStripeConnectedAccountId != '' && companyStripeConnectedAccountChargesEnabled && companyStripeConnectedAccountChargesEnabled == 'true'" class="mt-2">
              <CCol>
                <CButton shape="pill" color="outline-primary" @click="isOnlinePaiementAdding = true; newOnlinePaiement.montant = parseFloat(leftToPay).toFixed(2)"> <CIcon name="cil-euro" /> Créer un lien de paiement en ligne </CButton>
              </CCol>
            </CRow>
            <CRow v-else class="mt-2">
              <CCol>
                ⛔ Pour créer un lien de paiement en ligne, il est nécessaire de <router-link to="/">valider votre compte bancaire</router-link>
              </CCol>
            </CRow>
          </div>
        </div>
      </CCardBody>
    </CCard>



      <CCard class="mt-3">
        <CCardHeader
          @click="cardFactureCaracteristicsCollapse = !cardFactureCaracteristicsCollapse"
          style="padding-bottom: 15px !important; cursor: pointer;"
        >
          <strong>📃 Caractéristiques de votre facture</strong>
          <div class="card-header-actions">
            <CIcon v-if="!cardFactureCaracteristicsCollapse" name="cil-arrow-circle-bottom" size="lg"/>
            <CIcon v-else name="cil-arrow-circle-top" size="lg"/>
          </div>
        </CCardHeader>
        <CCollapse :show="cardFactureCaracteristicsCollapse">
          <CCardBody>
            <CForm>
              <p>
                <span>
                  Exercice comptable :
                  <strong>{{facture.exercice_comptable}}</strong>
                </span><br>
                <i>
                  <small>
                    Pour modifier l'exercice comptable de votre facture,
                    veuillez la dupliquer, choisir le nouvel exercice comptable puis archiver celle-ci
                  </small>
                </i>
              </p>

              <CInput
                label="Intitulé de votre facture "
                v-model="modifyFactureTitle" type="text"
                placeholder="Renseignez l'intitulé de votre facture"
                maxlength="149"
                :disabled="!facture.is_active"
                @input="$v.modifyFactureTitle.$touch()"
                :isValid="$v.modifyFactureTitle.$dirty ? !$v.modifyFactureTitle.$error : null"
              >
              </CInput>

              <CInput
                class="mt-3"
                label="Adresse de facturation "
                v-model="modifyFactureAddress" type="text"
                placeholder="Renseignez l'adresse de facturation"
                maxlength="300"
                :disabled="!facture.is_active"
                @input="$v.modifyFactureAddress.$touch()"
                :isValid="$v.modifyFactureAddress.$dirty ? !$v.modifyFactureAddress.$error : null"
              >
              </CInput>

              <CRow class="mb-2">
                <CCol>
                  <CRow>
                    <CCol tag="label">
                      Modalités de paiement possibles
                      <small class="text-danger" v-if="!isAtLeastOneModalitePaiementChosen">
                        Veuillez choisir au moins une modalité de paiement
                      </small>
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol class="form-inline">
                      <CInputCheckbox
                        label="Virement"
                        :disabled="!facture.is_active"
                        :custom="true"
                        name="modify-facture-input-modifyFactureIsModalitesVirement"
                        :checked.sync="modifyFactureIsModalitesVirement"
                        :inline="true"
                      />
                      <CInputCheckbox
                        label="Carte bancaire"
                        :disabled="!facture.is_active"
                        :custom="true"
                        name="modify-facture-input-modifyFactureIsModalitesCb"
                        :checked.sync="modifyFactureIsModalitesCb"
                        :inline="true"
                      />
                      <CInputCheckbox
                        label="Liquide"
                        :disabled="!facture.is_active"
                        :custom="true"
                        name="modify-facture-input-modifyFactureIsModalitesCash"
                        :checked.sync="modifyFactureIsModalitesCash"
                        :inline="true"
                      />
                      <CInputCheckbox
                        label="Chèque"
                        :disabled="!facture.is_active"
                        :custom="true"
                        name="modify-facture-input-modifyFactureIsModalitesCheque"
                        :checked.sync="modifyFactureIsModalitesCheque"
                        :inline="true"
                      />
                    </CCol>
                  </CRow>
                </CCol>
              </CRow>

              <CSelect
                :value.sync="modifyFactureDelaiPaiement"
                label="Délais de paiement"
                :options="delaiPaiementArrayOptions"
                :disabled="!facture.is_active"
                @input="$v.modifyFactureDelaiPaiement.$touch()"
                :isValid="$v.modifyFactureDelaiPaiement.$dirty ? !$v.modifyFactureDelaiPaiement.$error : null"
                custom
              />

              <CRow>
                <CCol sm="6">

                  <div v-if="$browserDetect.isSafari">
                    <b-form-group id="modify-facture-date_facturation-group" label="Date de facturation" label-for="modify-facture-date_facturation-input">
                      <b-form-datepicker
                        id="modify-facture-date_facturation-input"
                        name="modify-facture-date_facturation-input"
                        locale="fr"
                        :show-decade-nav="true"
                        :disabled="!facture.is_active"
                        placeholder="Choisissez une date"
                        v-model="modifyFactureDateFacturation" class="mb-2"
                        @input="$v.modifyFactureDateFacturation.$touch()"
                        :state="$v.modifyFactureDateFacturation.$dirty ? !$v.modifyFactureDateFacturation.$error : null">
                      </b-form-datepicker>
                      <small class="text-danger" v-if="!$v.modifyFactureDateFacturation.validDate">Veuillez entrer votre date au bon format (dd/mm/aaaa ou aaaa-mm-dd)</small>
                    </b-form-group>
                  </div>
                  <div v-else>
                    <CInput
                      label="Date de facturation"
                      v-model="modifyFactureDateFacturation" type="date"
                      placeholder="AAAA-MM-DD"
                      :disabled="!facture.is_active"
                      @input="$v.modifyFactureDateFacturation.$touch()"
                      :isValid="$v.modifyFactureDateFacturation.$dirty ? !$v.modifyFactureDateFacturation.$error : null"
                      invalid-feedback="Veuillez entrer votre date au bon format (dd/mm/aaaa ou aaaa-mm-dd)"
                    >
                    <template #description>
                      <div v-if="$browserDetect.isIE" class="text-info">
                        <small>Vous utilisez Internet Explorer, veuillez entrer votre
                        date au format AAAA-MM-DD (Par exemple 1975-11-03)</small>
                      </div>
                      <small class="text-danger" v-if="!$v.modifyFactureDateFacturation.validDate">Veuillez entrer votre date au bon format (dd/mm/aaaa ou aaaa-mm-dd)</small>
                    </template>
                    </CInput>
                  </div>

                </CCol>
                <CCol sm="6">

                  <div v-if="$browserDetect.isSafari">
                    <b-form-group id="modify-facture-date_echeance-group" label="Date d'échéance" label-for="modify-facture-date_echeance-input">
                      <b-form-datepicker
                        id="modify-facture-date_echeance-input"
                        name="modify-facture-date_echeance-input"
                        :disabled="!facture.is_active"
                        locale="fr"
                        :show-decade-nav="true"
                        placeholder="Choisissez une date"
                        v-model="modifyFactureDateEcheance" class="mb-2"
                        @input="$v.modifyFactureDateEcheance.$touch()"
                        :state="$v.modifyFactureDateEcheance.$dirty ? !$v.modifyFactureDateEcheance.$error : null">
                      </b-form-datepicker>
                      <small class="text-danger" v-if="!$v.modifyFactureDateEcheance.validDate">Veuillez entrer votre date au bon format (dd/mm/aaaa ou aaaa-mm-dd)</small>
                    </b-form-group>
                  </div>
                  <div v-else>
                    <CInput
                      label="Date d'échéance"
                      v-model="modifyFactureDateEcheance" type="date"
                      placeholder="AAAA-MM-DD"
                      :disabled="!facture.is_active"
                      @input="$v.modifyFactureDateEcheance.$touch()"
                      :isValid="$v.modifyFactureDateEcheance.$dirty ? !$v.modifyFactureDateEcheance.$error : null"
                      invalid-feedback="Veuillez entrer votre date au bon format (dd/mm/aaaa ou aaaa-mm-dd)"
                    >
                    <template #description>
                      <div v-if="$browserDetect.isIE" class="text-info">
                        <small>Vous utilisez Internet Explorer, veuillez entrer votre
                        date au format AAAA-MM-DD (Par exemple 1975-11-03)</small>
                      </div>
                      <small class="text-danger" v-if="!$v.modifyFactureDateEcheance.validDate">Veuillez entrer votre date au bon format (dd/mm/aaaa ou aaaa-mm-dd)</small>
                    </template>
                    </CInput>
                  </div>

                </CCol>
              </CRow>

              <CRow>
                <CCol sm="4">
                  Est-ce une demande d'accompte ?
                </CCol>
                <CCol sm="8">
                  <CInputRadioGroup
                    :checked.sync="modifyFactureIsAcompte"
                    :custom="true"
                    :inline="true"
                    :options='booleanArrayForm'
                    :disabled="!facture.is_active"
                  />
                </CCol>
              </CRow>


              <CTextarea
                class="mt-2"
                label="Message sur la facture"
                v-model="modifyFactureMessageFacture" type="text"
                placeholder="Vous pouvez renseigner un message personnalisé sur la facture"
                maxlength="300"
                :disabled="!facture.is_active"
                @input="$v.modifyFactureMessageFacture.$touch()"
                :isValid="$v.modifyFactureMessageFacture.$dirty ? !$v.modifyFactureMessageFacture.$error : null"
              >
              </CTextarea>
              <CRow>
                <CCol>
                  <div v-if="facture.is_active">
                    <CRow class="mt-2">
                      <CCol :sm="{size: 3, offset: 6}">
                        <CButton
                          :color="modifyFactureButtonStyle"
                          shape="pill" block class="px-4" size="sm"
                          @click='modifyFacture'
                          :disabled="$v.modifyFactureTitle.$invalid || $v.modifyFactureAddress.$invalid || $v.modifyFactureDelaiPaiement.$invalid || $v.modifyFactureDateFacturation.$invalid || $v.modifyFactureDateEcheance.$invalid || $v.modifyFactureIsAcompte.$invalid || $v.modifyFactureMessageFacture.$invalid || !isAtLeastOneModalitePaiementChosen || modifyFactureInProcess">
                          <CSpinner size="sm" label="modify facture spinner" v-if="modifyFactureInProcess"></CSpinner>
                          {{ modifyFactureButtonText }}
                        </CButton>
                      </CCol>
                      <CCol :sm="{size: 3}">
                        <CButton
                          :color="deleteFactureButtonStyle"
                          shape="pill" block class="px-4" size="sm"
                          @click='deleteFacture'
                          :disabled="deleteFactureInProcess">
                          <CSpinner size="sm" label="delete facture spinner" v-if="deleteFactureInProcess"></CSpinner>
                          {{ deleteFactureButtonText }}
                        </CButton>
                      </CCol>
                    </CRow>
                  </div>
                  <div v-else>
                    <CRow >
                      <CCol class="text-center">
                        <CButton
                          :color="activateFactureButtonStyle"
                          shape="pill" block class="px-4 mt-2" size="lg"
                          @click='activateFacture'
                          :disabled="activateFactureInProcess">
                          <CSpinner size="sm" label="activate facture spinner" v-if="activateFactureInProcess"></CSpinner>
                          {{ activateFactureButtonText }}
                        </CButton>
                      </CCol>
                    </CRow>
                  </div>
                </CCol>

              </CRow>

            </CForm>
          </CCardBody>
        </CCollapse>
    </CCard>


    <CCard v-if="onlinePaiements.length > 0" class="mt-3">
        <CCardHeader
          @click="cardFactureOnlinePaiementsCollapse = !cardFactureOnlinePaiementsCollapse"
          style="padding-bottom: 15px !important; cursor: pointer;"
        >
          <strong>💰 Paiements en ligne</strong>
          <div class="card-header-actions">
            <CIcon v-if="!cardFactureOnlinePaiementsCollapse" name="cil-arrow-circle-bottom" size="lg"/>
            <CIcon v-else name="cil-arrow-circle-top" size="lg"/>
          </div>
        </CCardHeader>
        <CCollapse :show="cardFactureOnlinePaiementsCollapse">
          <CCardBody>
            <CRow>
              <CCol>
                <CButton shape="pill" color="outline-success" @click="isOnlinePaiementAdding = true; newOnlinePaiement.montant = parseFloat(leftToPay).toFixed(2)">
                  <CIcon name="cil-euro" /> Créer un nouveau lien de paiement en ligne
                </CButton>
               </CCol>
             </CRow>
             <CRow class="mt-3">
              <CCol>
                <CDataTable
                  headVariant="light"
                  :items="onlinePaiementsRender"
                  :fields="onlinePaiementsRenderFields"
                  :noItemsView='{ noResults: "Aucun résultat", noItems: "Aucun résultat" }'
                >

                <template #montant="{item}">
                  <td class="text-center">
                    {{formatThousandSeparatorNumber(parseFloat(item.montant).toFixed(2))}} €
                  </td>
                </template>

                <template #is_paid="{item}">
                  <td class="text-center">
                    <span v-if="item.is_paid">Oui <br> <small> {{$dayjs(item.paiement_date).format('DD/MM/YYYY')}}</small> </span>
                    <span v-else>Non</span>
                  </td>
                </template>

                <template #is_relance="{item}">
                  <td class="text-center">
                    <span v-if="item.is_relance">Oui <br> {{onlinePaiementsFrequenceRelanceRender(item.relance_frequence)}}</span>
                    <span v-else>Non</span>
                  </td>
                </template>

                <template #last_date_relance="{item}">
                  <td class="text-center">
                    <span v-if="item.is_relance">{{$dayjs(item.last_date_relance).format('DD/MM/YYYY')}}</span>
                  </td>
                </template>




                <template #actions="{item}">
                  <td>
                    <CButtonGroup>
                      <CButton
                        class="ml-2"
                        color="outline-primary"
                        size="sm"
                        :disabled="!facture.is_active"
                        @click="copyCodeToClipBoard(vueBaseUrl + '/pages/paiement-en-ligne/' + item.random_url)">
                        Copier le lien de paiement
                      </CButton>
                      <CButton
                        v-if="!item.is_paid"
                        class="ml-2"
                        square
                        :disabled="!facture.is_active || deleteUniteFacturesInProcess"
                        @click="deleteOnlinePaiement(item.id)">
                        <CIcon class="text-danger" name="cil-trash"/>
                      </CButton>

                    </CButtonGroup>

                  </td>
                </template>

                </CDataTable>
              </CCol>
            </CRow>

          </CCardBody>
        </CCollapse>
    </CCard>


    <CCard class="mt-3">
        <CCardHeader
          @click="cardFacturesFraisCollapse = !cardFacturesFraisCollapse"
          style="padding-bottom: 15px !important; cursor: pointer;"
        >
          <strong>💸 Frais mission</strong>
          <div class="card-header-actions">
            <CIcon v-if="!cardFacturesFraisCollapse" name="cil-arrow-circle-bottom" size="lg"/>
            <CIcon v-else name="cil-arrow-circle-top" size="lg"/>
          </div>
        </CCardHeader>
        <CCollapse :show="cardFacturesFraisCollapse">
          <CCardBody>
            <factureViewFraisComponent v-if="facture.mission.id > 0" v-bind:missionPk="String(facture.mission.id)" />
          </CCardBody>
        </CCollapse>
    </CCard>


    <CCard class="mt-3">
        <CCardHeader
          @click="cardFactureContenuCollapse = !cardFactureContenuCollapse"
          style="padding-bottom: 15px !important; cursor: pointer;"
        >
          <strong>📝 Contenu de votre facture -
            <span v-if="parseFloat(sousTotalHT) == 0" class="text-danger">❌ A renseigner</span>
            <span v-else> Total de {{formatThousandSeparatorNumber(parseFloat(parseFloat(sousTotalHT).toFixed(2) - parseFloat(remiseTotaleHT).toFixed(2)).toFixed(2))}} € HT </span>
          </strong>
          <div class="card-header-actions">
            <CIcon v-if="!cardFactureContenuCollapse" name="cil-arrow-circle-bottom" size="lg"/>
            <CIcon v-else name="cil-arrow-circle-top" size="lg"/>
          </div>
        </CCardHeader>
        <CCollapse :show="cardFactureContenuCollapse">
          <CCardBody>
            <CRow>
              <CCol>
                <strong>
                  Ajouter ci-dessous les items de votre facture
                </strong> (chaque item correspond à une ligne de facturation).
                <CRow class="mt-3">
                  <CCol class="xl-6 align-self-center">
                    <CButton shape="pill" color="outline-primary" @click="getAllTasksForFacturation" :disabled="!facture.is_active">
                       <!--<CIcon name="cil-plus" />--> 💥 <strong>NEW</strong> - Générer votre facture à partir du reporting mission
                     </CButton>
                  </CCol>
                  <CCol class="xl-6 text-right align-self-center">

                    <router-link  :to="'/mission/' + facture.mission.id" target='_blank'>
                       <CIcon name="cil-layers" /> Accéder au tableau de bord mission
                     </router-link>

                     <br class="d-sm-block d-md-none">

                     <router-link  :to="'/manager-dashboard-tasks/' + facture.mission.client.id + '/' + facture.mission.id" target='_blank' class="ml-3">
                       <CIcon name="cil-clock" /> Accéder au reporting de la mission
                     </router-link>
                   </CCol>
                 </CRow>
                <b-form
                  v-on:submit.prevent="addUniteFacture(newUniteFactures.date, newUniteFactures.description, newUniteFactures.quantite, newUniteFactures.prix_unitaire, newUniteFactures.remise_ht, newUniteFactures.tva)">
                  <CDataTable
                    addTableClasses="no-border-table no-margin-bottom-table"
                    :header="true"
                    headVariant="light"
                    :items="[newUniteFactures]"
                    :fields="newUniteFacturesRenderFields"
                  >
                    <template #date="">
                      <td>
                        <CInput
                          v-model="newUniteFactures.date" type="date"
                          placeholder="Date (AAAA-MM-DD)"
                          @input="$v.newUniteFactures.date.$touch()"
                          :isValid="$v.newUniteFactures.date.$dirty ? !$v.newUniteFactures.date.$error : null"
                          invalid-feedback="Veuillez entrer votre date au bon format (dd/mm/aaaa ou aaaa-mm-dd)"
                          :disabled="!facture.is_active"
                        />
                      </td>
                    </template>
                    <template #description="">
                      <td v-if="isUniteFacturesAddingBigDescription == false" style="min-width:350px">
                        <CInput
                          style="margin-bottom: 0px;"
                          placeholder="Description"
                          type="text"
                          maxlength="150"
                          v-model="newUniteFactures.description"
                          @input="$v.newUniteFactures.description.$touch()"
                          :isValid="$v.newUniteFactures.description.$dirty ? !$v.newUniteFactures.description.$error : null"
                          :disabled="!facture.is_active">
                        </CInput>
                        <CButton @click="isUniteFacturesAddingBigDescription = true" size="sm" color="outline-primary" class="mt-2" :disabled="!facture.is_active">
                          Ajouter une description sur plusieurs lignes
                        </CButton>
                      </td>
                      <td v-else style="min-width:350px">
                        <CTextarea
                          placeholder="Description"
                          type="text"
                          maxlength="1000"
                          rows="5"
                          v-model="newUniteFactures.description"
                          @input="$v.newUniteFactures.description.$touch()"
                          :isValid="$v.newUniteFactures.description.$dirty ? !$v.newUniteFactures.description.$error : null"
                          :disabled="!facture.is_active">
                        </CTextarea>
                      </td>
                    </template>
                    <template #quantite="">
                      <td>
                        <CInput
                          placeholder="Quantité"
                          type="number"
                          v-model="newUniteFactures.quantite"
                          @input="$v.newUniteFactures.quantite.$touch()"
                          :isValid="$v.newUniteFactures.quantite.$dirty ? !$v.newUniteFactures.quantite.$error : null"
                          min="0" max="100000000" step=".01" @blur="newUniteFactures.quantite = parseFloat(newUniteFactures.quantite).toFixed(2)"
                          :disabled="!facture.is_active">
                        </CInput>
                      </td>
                    </template>
                    <template #prix_unitaire="">
                      <td>
                        <CInput
                          placeholder="Prix unitaire (en € HT)"
                          type="number"
                          v-model="newUniteFactures.prix_unitaire"
                          @input="$v.newUniteFactures.prix_unitaire.$touch()"
                          :isValid="$v.newUniteFactures.prix_unitaire.$dirty ? !$v.newUniteFactures.prix_unitaire.$error : null"
                          min="-10000000" max="100000000" step=".01" @blur="newUniteFactures.prix_unitaire = parseFloat(newUniteFactures.prix_unitaire).toFixed(2)"
                          :disabled="!facture.is_active">
                          <template #append-content>€</template>
                        </CInput>
                      </td>
                    </template>
                    <template #remise_ht="">
                      <td>
                        <CInput
                          placeholder="Remise tarifaire"
                          type="number"
                          v-model="newUniteFactures.remise_ht"
                          @input="$v.newUniteFactures.remise_ht.$touch()"
                          :isValid="$v.newUniteFactures.remise_ht.$dirty ? !$v.newUniteFactures.remise_ht.$error : null"
                          min="0" max="100000000" step=".01" @blur="newUniteFactures.remise_ht = parseFloat(newUniteFactures.remise_ht).toFixed(2)"
                          :disabled="!facture.is_active">
                          <template #append-content>€ de remise</template>
                        </CInput>
                      </td>
                    </template>
                    <template #tva="">
                      <td>
                        <CInput
                          placeholder="Taux de TVA (en %)"
                          type="number"
                          v-model="newUniteFactures.tva"
                          @input="$v.newUniteFactures.tva.$touch()"
                          :isValid="$v.newUniteFactures.tva.$dirty ? !$v.newUniteFactures.tva.$error : null"
                          min="0" max="100000000" step=".1" @blur="newUniteFactures.tva = parseFloat(newUniteFactures.tva).toFixed(1)"
                          :disabled="!facture.is_active">
                          <template #append-content>% de TVA</template>
                        </CInput>
                      </td>
                    </template>
                    <template #actions="">
                      <td>
                        <CButton
                          type="submit"
                          :color="addUniteFacturesButtonStyle"
                          square
                          :disabled="!facture.is_active || $v.newUniteFactures.$invalid || addUniteFacturesInProcess"
                        >
                            {{addUniteFacturesButtonText}}
                        </CButton>
                      </td>
                    </template>
                  </CDataTable>
                </b-form>
              </CCol>
            </CRow>

            <CRow v-if="$browserDetect.isIE || $browserDetect.isSafari" class="text-info">
              <CCol>
                <small>Vous utilisez Internet Explorer ou Safari, veuillez entrer votre
                date au format AAAA-MM-DD (Par exemple 1975-11-03)</small>
                <small class="text-danger" v-if="!$v.newUniteFactures.date.validDate"><br>Veuillez entrer votre date au bon format (dd/mm/aaaa ou aaaa-mm-dd)</small>
              </CCol>
            </CRow>

            <CRow class="mt-3">
              <CCol>
                <CDataTable
                  headVariant="light"
                  :items="uniteFacturesRender"
                  :fields="uniteFacturesRenderFields"
                  :noItemsView='{ noResults: "Aucun résultat", noItems: "Aucun résultat" }'
                >

                <template #date="{item}">
                  <td>
                    {{$dayjs(item.date).format('DD/MM/YYYY')}}
                  </td>
                </template>

                <template #description="{item}">
                  <td style="white-space: pre-line;"><small>{{item.description}}</small></td>
                </template>

                <template #quantite="{item}">
                  <td>
                    {{formatThousandSeparatorNumber(parseFloat(item.quantite).toFixed(2))}}
                  </td>
                </template>

                <template #prix_unitaire="{item}">
                  <td>
                    {{formatThousandSeparatorNumber(parseFloat(item.prix_unitaire).toFixed(2))}} €
                  </td>
                </template>

                <template #montant_total_ht="{item}">
                  <td>
                    {{formatThousandSeparatorNumber(parseFloat(item.montant_total_ht).toFixed(2))}} €
                  </td>
                </template>

                <template #remise_ht="{item}">
                  <td>
                    {{formatThousandSeparatorNumber(parseFloat(item.remise_ht).toFixed(2))}} €
                  </td>
                </template>

                <template #tva="{item}">
                  <td>
                    {{formatThousandSeparatorNumber(parseFloat(item.tva).toFixed(2))}} % de TVA
                  </td>
                </template>

                <template #montant_total_ttc="{item}">
                  <td>
                    {{formatThousandSeparatorNumber(parseFloat(item.montant_total_ttc).toFixed(2))}} €
                  </td>
                </template>

                <template #actions="{item}">
                  <td>
                    <CButtonGroup>
                      <CButton
                        class="ml-1"
                        square
                        v-c-tooltip="{content: 'Modifier',placement: 'top'}"
                        :disabled="!facture.is_active"
                        @click="showModifyUniteFactures(item)">
                        <CIcon class="text-primary" name="cil-pencil"/>
                      </CButton>
                      <CButton
                        class="ml-1"
                        square
                        v-c-tooltip="{content: 'Supprimer',placement: 'top'}"
                        :disabled="!facture.is_active || deleteUniteFacturesInProcess"
                        @click="deleteUniteFactures(item.id)">
                        <CIcon class="text-danger" name="cil-trash"/>
                      </CButton>

                    </CButtonGroup>

                  </td>
                </template>

                <template #no-items-view>
                  <CRow>
                    <CCol class="text-center">
                      Veuillez enregistrer des items de facturation
                    </CCol>
                  </CRow>
                </template>
                </CDataTable>
              </CCol>
            </CRow>
            <CRow>
              <CCol class="text-right" style="font-size: 1.4em;">
                <CRow class="mt-2">
                  <CCol sm="10">
                    <strong> Sous-total HT</strong>
                  </CCol>
                  <CCol sm="2">
                    <strong>{{formatThousandSeparatorNumber(parseFloat(sousTotalHT).toFixed(2))}} €</strong>
                  </CCol>
                </CRow>
                <CRow class="mt-2">
                  <CCol sm="10">
                    <strong> Remise globale HT</strong>
                  </CCol>
                  <CCol sm="2">
                    <strong>{{formatThousandSeparatorNumber(parseFloat(remiseTotaleHT).toFixed(2))}} €</strong>
                  </CCol>
                </CRow>
                <CRow class="mt-2">
                  <CCol sm="10">
                    <strong> Total net HT</strong>
                  </CCol>
                  <CCol sm="2">
                    <strong>{{formatThousandSeparatorNumber(parseFloat(parseFloat(sousTotalHT).toFixed(2) - parseFloat(remiseTotaleHT).toFixed(2)).toFixed(2))}} €</strong>
                  </CCol>
                </CRow>
                <CRow class="mt-2">
                  <CCol sm="10">
                    <strong> Total TTC</strong>
                  </CCol>
                  <CCol sm="2">
                    <strong>{{formatThousandSeparatorNumber(parseFloat(totalTTC).toFixed(2))}} €</strong>
                  </CCol>
                </CRow>
              </CCol>
            </CRow>
          </CCardBody>
        </CCollapse>
    </CCard>

    <CCard class="mt-3" v-if="!isAvoir">
        <CCardHeader
          @click="cardFacturePaiementCollase = !cardFacturePaiementCollase"
          style="padding-bottom: 15px !important; cursor: pointer;"
        >
          <strong>💰 Paiements réalisés -
            <span v-if="parseFloat(totalPaid) == 0" class="text-danger">❌ Aucun paiement encore renseigné</span>
            <span v-else> {{formatThousandSeparatorNumber(parseFloat(totalPaid).toFixed(2))}} € payé </span>
          </strong>
          <div class="card-header-actions">
            <CIcon v-if="!cardFacturePaiementCollase" name="cil-arrow-circle-bottom" size="lg"/>
            <CIcon v-else name="cil-arrow-circle-top" size="lg"/>
          </div>
        </CCardHeader>
        <CCollapse :show="cardFacturePaiementCollase">
          <CCardBody>
            <CRow>
              <CCol>
                <strong> Ajouter un paiement </strong>

                <b-form
                  v-on:submit.prevent="addPaiement(newPaiement.date, newPaiement.modalites, newPaiement.montant_ttc)">
                <CDataTable
                  addTableClasses="no-border-table no-margin-bottom-table"
                  :header="true"
                  headVariant="light"
                  :items="[newPaiement]"
                  :fields="newPaiementRenderFields"
                >
                  <template #date="">
                    <td>
                      <CInput
                        v-model="newPaiement.date" type="date"
                        placeholder="Date (AAAA-MM-DD)"
                        @input="$v.newPaiement.date.$touch()"
                        :isValid="$v.newPaiement.date.$dirty ? !$v.newPaiement.date.$error : null"
                        invalid-feedback="Veuillez entrer votre date au bon format (dd/mm/aaaa ou aaaa-mm-dd)"
                        :disabled="!facture.is_active"
                      >
                      <template #description>
                        <div v-if="$browserDetect.isIE || $browserDetect.isSafari" class="text-info">
                          <small>Vous utilisez Internet Explorer ou Safari, veuillez entrer votre
                          date au format AAAA-MM-DD (Par exemple 1975-11-03)</small>
                        </div>
                        <small class="text-danger" v-if="!$v.newPaiement.date.validDate">Veuillez entrer votre date au bon format (dd/mm/aaaa ou aaaa-mm-dd)</small>
                      </template>
                      </CInput>
                    </td>
                  </template>
                  <template #modalites="">
                    <td>
                      <CSelect
                        :value.sync="newPaiement.modalites"
                        :options="modalitesPaiementArrayOptions"
                        @input="$v.newPaiement.modalites.$touch()"
                        :isValid="$v.newPaiement.modalites.$dirty ? !$v.newPaiement.modalites.$error : null"
                        :disabled="!facture.is_active"
                        custom
                      />
                    </td>
                  </template>
                  <template #montant_ttc="">
                    <td>
                      <CInput
                        placeholder="Montant (TTC en €)"
                        type="number"
                        v-model="newPaiement.montant_ttc"
                        @input="$v.newPaiement.montant_ttc.$touch()"
                        :isValid="$v.newPaiement.montant_ttc.$dirty ? !$v.newPaiement.montant_ttc.$error : null"
                        min="0" max="100000000" step=".01" @blur="newPaiement.montant_ttc = parseFloat(newPaiement.montant_ttc).toFixed(2)"
                        :disabled="!facture.is_active">
                      </CInput>
                    </td>
                  </template>

                  <template #actions="">
                    <td>
                      <CButton
                        type="submit"
                        :color="addPaiementButtonStyle"
                        square
                        :disabled="!facture.is_active || $v.newPaiement.$invalid || addPaiementInProcess"
                      >
                          {{addPaiementButtonText}}
                      </CButton>
                    </td>
                  </template>
                </CDataTable>
                </b-form>
              </CCol>
            </CRow>

            <CRow class="mt-3">
              <CCol>
                <CDataTable
                  headVariant="light"
                  :items="paiementsRender"
                  :fields="paiementsRenderFields"
                  :noItemsView='{ noResults: "Aucun résultat", noItems: "Aucun résultat" }'
                >
                <template #date="{item}">
                  <td>
                    <span>{{$dayjs(item.date).format('DD/MM/YYYY')}}</span>
                  </td>
                </template>
                <template #montant_ttc="{item}">
                  <td>
                    {{formatThousandSeparatorNumber(parseFloat(item.montant_ttc).toFixed(2))}} €
                  </td>
                </template>

                <template #modalites="{item}">
                  <td>
                    {{modalitesPaiementRender(item.modalites)}}
                  </td>
                </template>

                <template #actions="{item}">
                  <td>
                    <CButtonGroup>
                      <CButton
                        color="primary"
                        variant="outline"
                        square
                        size="sm"
                        :disabled="!facture.is_active"
                        @click="downloadPaiement(item)"
                      >
                          Editer le reçu
                      </CButton>
                      <CButton
                        class="ml-0 ml-md-1"
                        color="dark"
                        variant="outline"
                        square
                        size="sm"
                        :disabled="!facture.is_active"
                        @click="showModifyPaiement(item)"
                      >
                          Modifier
                      </CButton>
                      <CButton
                        class="ml-0 ml-md-1"
                        :color="deletePaiementButtonStyle"
                        square
                        size="sm"
                        :disabled="!facture.is_active || deletePaiementInProcess"
                        @click="deletePaiement(item.id)"
                      >
                          {{deletePaiementButtonText}}
                      </CButton>
                    </CButtonGroup>
                  </td>
                </template>

                <template #no-items-view>
                  <CRow>
                    <CCol class="text-center">
                      Vous pouvez enregistrer des paiements au fur et à mesure que vous les recevez
                    </CCol>
                  </CRow>
                </template>
                </CDataTable>
              </CCol>
            </CRow>

            <CRow>
              <CCol class="text-right" style="font-size: 1.4em;">
                <CRow class="mt-2">
                  <CCol sm="10">
                    <strong> Total payé</strong>
                  </CCol>
                  <CCol sm="2">
                    <strong>{{formatThousandSeparatorNumber(parseFloat(totalPaid).toFixed(2))}} €</strong>
                  </CCol>
                </CRow>
                <CRow class="mt-2">
                  <CCol sm="10">
                    <strong> Reste à payer</strong>
                  </CCol>
                  <CCol sm="2">
                    <strong>{{formatThousandSeparatorNumber(parseFloat(parseFloat(totalTTC).toFixed(2) - parseFloat(totalPaid).toFixed(2)).toFixed(2))}} €</strong>
                  </CCol>
                </CRow>
              </CCol>
            </CRow>
          </CCardBody>
        </CCollapse>
    </CCard>

  </div>
</template>

<script>
import { vueBaseUrl } from '@/variables/localVariables'

import { validationMixin } from 'vuelidate'
import { required, minValue, maxValue, maxLength, minLength, email } from 'vuelidate/lib/validators'
import renderMixins from '@/mixins/renderMixins'
import { timeOperations } from '@/mixins/utilMixins'
import userMixins from '@/mixins/userMixins'

import { APIManagerConnected } from '@/api/APIManagerConnected'

import { isDate } from '@/validators/validators'
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

import factureViewFraisComponent from '@/components/fraisComponents/factureViewFraisComponent'

const apiManagerConnected = new APIManagerConnected()

export default {
  name: 'ModifyOrArchiveFacture',
  components: {
    Loading,
    factureViewFraisComponent
  },
  mixins: [
    validationMixin,
    renderMixins,
    timeOperations,
    userMixins
  ],
  data: function () {
    const today = this.$dayjs().format('YYYY-MM-DD')
    const oneMonthAgo = this.$dayjs().subtract('1', 'months').format('YYYY-MM-DD')
    return {

      vueBaseUrl: vueBaseUrl,

      cardFactureCaracteristicsCollapse: false,
      cardFactureOnlinePaiementsCollapse: false,
      cardFacturesFraisCollapse: false,
      cardFactureContenuCollapse: true,
      cardFacturePaiementCollase: true,

      // ----------- GETTERS -------------
      isFactureLoading: false,
      facture: {
        id: 0,
        exercice_comptable: 2020,
        facture_number: '',
        facture_title: '',
        facturation_address: '',
        delai_paiement: '30',
        date_facturation: '',
        date_echeance: '',
        is_acompte: false,
        is_modalites_virement: false,
        is_modalites_cb: false,
        is_modalites_cash: false,
        is_modalites_cheque: false,
        message_facture: '',
        is_active: true,
        has_been_sent: false,
        mission: {
          id: 0,
          name: '',
          client: {
            id: 0,
            name: ''
          },
          type: '',
          state: '',
          montant_ht: '',
          starting_date: '',
          ending_date: '',
          description: '',
          is_active: '',
          created_at: '',

          contact: {
            id: 0,
            first_name: '',
            last_name: '',
            full_name: '',
          },
          referent: {
            id: 0,
            full_name: '',
            user: {
              id: 0,
              first_name: '',
              last_name: '',
              username: ''
            }
          },
          collaborateurs: []
        },
      },

      // ---------------- DUPLICATE FACTURE
      isFactureDuplicating: false,
      duplicateFactureInProcess: false,
      duplicateFactureButtonStyle: 'outline-dark',
      duplicateFactureButtonText: 'Dupliquer cette facture',

      // ---------------- MODIFY FACTURE ------------
      isFactureModified: false,
      modifyFactureTitle: '',
      modifyFactureAddress: '',
      modifyFactureDelaiPaiement: '30',
      modifyFactureDateFacturation: '',
      modifyFactureDateEcheance: '',
      modifyFactureIsAcompte: 'false',
      modifyFactureIsModalitesVirement: false,
      modifyFactureIsModalitesCb: false,
      modifyFactureIsModalitesCash: false,
      modifyFactureIsModalitesCheque: false,
      modifyFactureMessageFacture: '',

      delaiPaiementArrayOptions: [
        { label: '15 jours', value: '15' },
        { label: '30 jours', value: '30' },
        { label: '60 jours', value: '60' },
        { label: 'Payable dès réception', value: 'RECEP' },
      ],
      facturesModalitesPaiementArrayOptions: [
        { label: 'Virement', value: 'VIR' },
        { label: 'Carte bancaire', value: 'CB' },
        { label: 'Espèces', value: 'CASH' },
      ],

      modifyFactureButtonText: 'Modifier cette facture',
      modifyFactureButtonStyle: 'outline-primary',
      modifyFactureInProcess: false,

      // ---------------- MARK FACTURE AS SENT -------
      markFactureAsSentInProcess: false,

      // ---------------- ARCHIVE FACTURE ------------
      deleteFactureInProcess: false,
      deleteFactureButtonText: "Archiver cette facture",
      deleteFactureButtonStyle: 'outline-danger',
      isFactureArchived: false,

      // ---------------- ACTIVATE FACTURE ------------
      activateFactureInProcess: false,
      activateFactureButtonText: "Réactiver cette facture",
      activateFactureButtonStyle: 'outline-primary',

      // ----------------- SEND FACTURE BY EMAIL ---------
      isSendingFactureByEmail: false,
      sendFactureClientEmail: '',
      sendFactureCustomizedMessage: '',
      sendFactureAnswerEmail: '',

      sendFactureEmailInProcess: false,
      sendFactureEmailButtonStyle: 'outline-primary',
      sendFactureEmailButtonText: 'Envoyer votre facture',

      isFactureSent: false,

      // ---------------- UNITES FACTURES ------------
      uniteFactures: [],
      uniteFacturesRender: [],
      uniteFacturesRenderFields: [
        { key: "actions", label: "", _style: "min-width: 100px; font-size: 14px"},
        { key: "date", label: "Date", _style: "min-width: 50px; font-size: 14px"},
        { key: "description", label: "Description", _style: "min-width: 200px; font-size: 14px"},
        { key: "quantite", label: "Quantité", _style: "min-width: 100px; font-size: 14px"},
        { key: "prix_unitaire", label: "Prix unitaire HT", _style: "min-width: 150px; font-size: 14px"},
        { key: "montant_total_ht", label: "Total HT", _style: "min-width: 150px; font-size: 14px;"},
        { key: "remise_ht", label: "Remise HT", _style: "min-width: 150px; font-size: 14px"},
        { key: "tva", label: "TVA", _style: "min-width: 100px; font-size: 14px"},
        { key: "montant_total_ttc", label: "Total TTC", _style: "min-width: 150px; font-size: 14px"},
      ],
      isUniteFacturesLoading: false,

      // ------------ ADD UNITE FACTURE --------
      newUniteFacturesRenderFields: [
        { key: "date", label: "", _style: "min-width: 100px;"},
        { key: "description", label: "", _style: "min-width: 250px;"},
        { key: "quantite", label: "", _style: "min-width: 150px;"},
        { key: "prix_unitaire", label: "", _style: "min-width: 150px;"},
        { key: "remise_ht", label: "", _style: "min-width: 200px;"},
        { key: "tva", label: "", _style: "min-width: 200px;"},
        { key: "actions", label: "", _style: "min-width: 100px;"},
      ],
      newUniteFactures: {
        'date': today,
        'description': '',
        'quantite': 1,
        'prix_unitaire': '',
        'remise_ht': 0,
        'tva': 20
      },

      isUniteFacturesAddingBigDescription: false,

      tvaArrayOptions: [
        { value: '20.0', label: '20% TVA FR' },
        { value: '10.0', label: '10% TVA FR' },
        { value: '5.5', label: '5,5% TVA FR' },
        { value: '2.1', label: '2,1% TVA FR' },
        { value: '0.9', label: '0,9% TVA FR' },
        { value: '0.0', label: 'Pas de TVA FR' },
      ],

      addUniteFacturesButtonText: 'Ajouter',
      addUniteFacturesButtonStyle: 'dark',
      addUniteFacturesInProcess: false,

      // ---------- MODIFY UNTIES FACTURES ---
      isUniteFacturesModifying: false,

      modifyUniteFacturesId: 0,
      modifyUniteFacturesDate: '',
      modifyUniteFacturesDescription: '',
      modifyUniteFacturesQuantite: '',
      modifyUniteFacturesPrixUnitaire: '',
      modifyUniteFacturesRemiseHT: '',
      modifyUniteFacturesTVA: '',

      modifyUniteFacturesButtonText: 'Modifier',
      modifyUniteFacturesButtonStyle: 'outline-primary',
      modifyUniteFacturesInProcess: false,

      deleteUniteFacturesButtonText: 'Supprimer',
      deleteUniteFacturesButtonStyle: 'outline-danger',
      deleteUniteFacturesInProcess: false,

      // --------------- TASK TO MODIFY UNITE FACTURES ---------
      isTasksLoading: false,
      filterTasksStartDate: oneMonthAgo,
      filterTasksEndDate: today,
      isNotBilled: 'true',

      isChoosingTaskToGenerateUniteFacture: false,
      tasks: [],
      tasksRenderFields: [
        { key: "actions", label: ""},
        { key: "collaborateur_full_name", label: "Collaborateur"},
        { key: "date", label: "Date", sortable: true },
        { key: "duree", label: "Durée", sortable: true},
        { key: "description", label: "Description", filter: false},
      ],

      chosenTasks: [],
      fromTaskUniteFactureDict: {},
      tauxChosenForUniteFactureFromTask: 'tjh',
      tauxChosenForUniteFactureFromTasksArrayForm: [
        { label: "Taux horaire", value: 'tjh' },
        { label: 'Taux journalier', value: 'tjm' },
      ],

      isAddingMultipleUnitesFacture: false,
      isMarkingTasksAsBilled : false,

      isContenuCreatedFromTasks: false,

      // ---------------- PAIEMENTS ------------
      paiements: [],
      paiementsRender: [],
      paiementsRenderFields: [
        { key: "date", label: "Date", _style: "min-width: 100px; font-size: 14px"},
        { key: "modalites", label: "Modalités de paiement", _style: "min-width: 150px; font-size: 14px"},
        { key: "montant_ttc", label: "Montant TTC", _style: "min-width: 100px; font-size: 14px"},
        { key: "actions", label: "Actions", _style: "min-width: 150px; font-size: 14px"},
      ],
      isPaiementsLoading: false,

      // ------------ ADD PAIEMENT --------
      newPaiementRenderFields: [
        { key: "date", label: "", _style: "min-width: 100px;"},
        { key: "modalites", label: "", _style: "min-width: 150px;"},
        { key: "montant_ttc", label: "", _style: "min-width: 100px;"},
        { key: "actions", label: "", _style: "min-width: 150px;"},
      ],
      newPaiement: {
        'date': today,
        'modalites': 'VIR',
        'montant_ttc': '',
      },

      modalitesPaiementArrayOptions: [
        { value: 'VIR', label: 'Virement' },
        { value: 'CB', label: 'Carte bancaire' },
        { value: 'CASH', label: 'Espèces' },
        { value: 'CHEQ', label: 'Chèque' },
      ],


      addPaiementButtonText: 'Ajouter',
      addPaiementButtonStyle: 'dark',
      addPaiementInProcess: false,

      // ---------- MODIFY UNTIES FACTURES ---
      isPaiementModifying: false,

      modifyPaiementId: 0,
      modifyPaiementDate: '',
      modifyPaiementModalites: '',
      modifyPaiementMontantTTC: '',

      modifyPaiementButtonText: 'Modifier',
      modifyPaiementButtonStyle: 'outline-primary',
      modifyPaiementInProcess: false,

      deletePaiementButtonText: 'Supprimer',
      deletePaiementButtonStyle: 'outline-danger',
      deletePaiementInProcess: false,


      // ------------ ADD ONLINE PAIEMENT --------

      onlinePaiements: [],
      onlinePaiementsRender: [],
      onlinePaiementsRenderFields: [
        { key: "montant", label: "Montant", _style: "min-width: 100px; font-size: 14px"},
        { key: "is_paid", label: "A été payé", _style: "min-width: 150px; font-size: 14px"},
        { key: "is_relance", label: "Relance mise en place", _style: "min-width: 150px; font-size: 14px"},
        { key: "last_date_relance", label: "Dernière date de relance", _style: "min-width: 100px; font-size: 14px"},
        { key: "actions", label: "", _style: "min-width: 150px; font-size: 14px"},
      ],
      isOnlinePaiementsLoading: false,

      newOnlinePaiement: {
        'montant': '',
        'is_relance': 'false',
        'relance_frequence': 'WEEK',
      },

      createdOnlinePaiement: {
        random_url: ''
      },
      copiedToasts: 0,

      relanceFrequenceArrayOptions: [
        { value: 'WEEK', label: 'Hebdomadaire' },
        { value: 'TWO_WEEKS', label: 'Toutes les deux semaines' },
        { value: 'MONTH', label: 'Mensuel' },
      ],
      isOnlinePaiementAdding: false,
      addOnlinePaiementInProcess: false,
      isOnlinePaiementAdded: false,

      isOnlinePaiementDeleted: false,

      booleanArrayForm: [
        { label: 'Oui', value: 'true' },
        { label: "Non", value: 'false' },
      ],

    }
  },
  computed: {

    companyStripeConnectedAccountId () {
      return this.$store.state.user.companyStripeConnectedAccountId
    },

    companyStripeConnectedAccountChargesEnabled () {
      return this.$store.state.user.companyStripeConnectedAccountChargesEnabled
    },

    exerciceComptableYears () {
      var years = [{'label': '', 'value': ''}]
      for (var i=this.facture.exercice_comptable-2; i<=this.facture.exercice_comptable+2; i++) {
        var value = String(i)
        years.push({'label': value, 'value': i})
      }
      return years
    },

    isAtLeastOneModalitePaiementChosen () {
      if (this.modifyFactureIsModalitesVirement || this.modifyFactureIsModalitesCb || this.modifyFactureIsModalitesCash || this.modifyFactureIsModalitesCheque) {
        return true
      }
      return false
    },

    isLoading () {
      if (this.isFactureLoading || this.isUniteFacturesLoading || this.isPaiementsLoading || this.markFactureAsSentInProcess || this.addOnlinePaiementInProcess || this.isOnlinePaiementsLoading || this.isTasksLoading || this.isAddingMultipleUnitesFacture || this.isMarkingTasksAsBilled) {
        return true
      }
      return false
    },

    isAvoir () {
      if ((this.sousTotalHT - this.remiseTotaleHT) >= 0) {
        return false
      }
      else {
        return true
      }
    },

    isRetard () {
      var today = this.$dayjs()
      var echeanceDate = this.$dayjs(this.facture.date_echeance)
      if (today > echeanceDate) {
        return true
      }
      return false
    },

    leftToPay () {
      var total_to_pay = 0
      for (var i=0; i < this.uniteFactures.length; i++) {
        var sousTotalHT = this.uniteFactures[i].quantite * this.uniteFactures[i].prix_unitaire
        var remiseHT = parseFloat(this.uniteFactures[i].remise_ht)
        var tva = this.uniteFactures[i].tva * (sousTotalHT - remiseHT) / 100
        total_to_pay = total_to_pay +  sousTotalHT - remiseHT + tva
      }

      var total_paid = 0
      for (var j=0; j < this.paiements.length; j++) {
        total_paid = total_paid + parseFloat(this.paiements[j].montant_ttc)
      }
      return total_to_pay - total_paid
    },

    remiseTotaleHT () {
      var total = 0
      for (var i=0; i < this.uniteFactures.length; i++) {
        total = total + parseFloat(this.uniteFactures[i].remise_ht)
      }
      return total
    },

    sousTotalHT () {
      var total = 0
      for (var i=0; i < this.uniteFactures.length; i++) {
        total = total + this.uniteFactures[i].quantite * this.uniteFactures[i].prix_unitaire
      }
      return total
    },

    token () {
      return this.$store.state.auth.token
    },

    totalPaid () {
      var total = 0
      for (var i=0; i < this.paiements.length; i++) {
        total = total + parseFloat(this.paiements[i].montant_ttc)
      }
      return total
    },

    totalTTC () {
      var total = 0
      for (var i=0; i < this.uniteFactures.length; i++) {
        var sousTotalHT = this.uniteFactures[i].quantite * this.uniteFactures[i].prix_unitaire
        var remiseHT = parseFloat(this.uniteFactures[i].remise_ht)
        var tva = this.uniteFactures[i].tva * (sousTotalHT - remiseHT) / 100
        total = total +  sousTotalHT - remiseHT + tva
      }
      return total
    },




  },
  validations: {

    // ---------- MODIFY FACTURES ------------
    modifyFactureTitle: {
      required,
      maxLength: maxLength(149),
      minLength: minLength(2)
    },
    modifyFactureAddress: {
      required,
      maxLength: maxLength(300),
      minLength: minLength(2)
    },
    modifyFactureDelaiPaiement: {
      required,
    },
    modifyFactureDateFacturation: {
      required,
      validDate(value) {
        return isDate(value)
      }
    },
    modifyFactureDateEcheance: {
      required,
      validDate(value) {
        return isDate(value)
      }
    },
    modifyFactureIsAcompte: {
      required,
    },

    modifyFactureMessageFacture: {
      maxLength: maxLength(300),
      minLength: minLength(2)
    },

    // --------- SEND FACTURE EMAIL ---------------
    sendFactureClientEmail: {
      email,
      maxLength: maxLength(249),
      minLength: minLength(2),
      required
    },
    sendFactureCustomizedMessage: {
      maxLength: maxLength(499),
      minLength: minLength(2)
    },
    sendFactureAnswerEmail: {
      email,
      maxLength: maxLength(249),
      minLength: minLength(2),
      required
    },

    // ---------- A NEW UNITE FACTURES ------------
    newUniteFactures: {
      date: {
        required,
        validDate(value) {
          return isDate(value)
        }
      },
      description: {
        required,
        minLength: minLength(5),
        maxLength: maxLength(1000)
      },
      quantite: {
        required,
        minValue: minValue(0),
        maxValue: maxValue(1000000)
      },
      prix_unitaire: {
        required,
        minValue: minValue(-10000000),
        maxValue: maxValue(100000000)
      },
      remise_ht: {
        minValue: minValue(0),
        maxValue: maxValue(100000000)
      },
      tva: {
        required,
      },
    },


    // ---------- MODIFY UNITE FACTURES ------------

    modifyUniteFacturesDate: {
      required,
      validDate(value) {
        return isDate(value)
      }
    },
    modifyUniteFacturesDescription: {
      required,
      minLength: minLength(5),
      maxLength: maxLength(1000)
    },
    modifyUniteFacturesQuantite: {
      required,
      minValue: minValue(0),
      maxValue: maxValue(1000000)
    },
    modifyUniteFacturesPrixUnitaire: {
      required,
      minValue: minValue(-10000000),
      maxValue: maxValue(100000000)
    },
    modifyUniteFacturesRemiseHT: {
      required,
      minValue: minValue(0),
      maxValue: maxValue(100000000)
    },
    modifyUniteFacturesTVA: {
      required,
    },


    // ---------- A NEW PAIEMENT ------------
    newPaiement: {
      date: {
        required,
        validDate(value) {
          return isDate(value)
        }
      },
      modalites: {
        required,
      },
      montant_ttc: {
        required,
        minValue: minValue(1),
        maxValue: maxValue(100000000)
      },
    },



    // ---------- MODIFY UNITE FACTURES ------------

    modifyPaiementDate: {
      required,
      validDate(value) {
        return isDate(value)
      }
    },
    modifyPaiementModalites: {
        required,
    },
    modifyPaiementMontantTTC: {
      required,
      minValue: minValue(1),
      maxValue: maxValue(100000000)
    },


    // ---------- ONLINE PAIEMENT ------------
    newOnlinePaiement: {
      montant: {
        required,
        minValue: minValue(1),
        maxValue: maxValue(100000000)
      },
      is_relance: {
        required,
      },
      relance_frequence: {
        required,
      },
    },
  },

  created: function() {
    this.$dayjs.locale('fr')
    this.getFacture()
    this.getAllFactureUnitesFactures()
    this.getAllFacturePaiements()
    this.getAllFactureOnlinePaiements()
  },
  watch: {
    uniteFactures: function (newUniteFactures) {
      if (newUniteFactures.length == 0) {
        this.uniteFacturesRender = []
      }
      else {
        var final_array = []
        for (var i = 0; i < newUniteFactures.length; i++) {

          var sousTotalHT = newUniteFactures[i].quantite * newUniteFactures[i].prix_unitaire
          var remiseHT = parseFloat(newUniteFactures[i].remise_ht)
          var tva = newUniteFactures[i].tva * (sousTotalHT - remiseHT) / 100
          var montantTotalTTC = sousTotalHT - remiseHT + tva

          final_array.push(
            {
              'id': newUniteFactures[i].id,
              'date': newUniteFactures[i].date,
              'description': newUniteFactures[i].description,
              'quantite': newUniteFactures[i].quantite,
              'prix_unitaire': newUniteFactures[i].prix_unitaire,
              'montant_total_ht': sousTotalHT,
              'remise_ht': newUniteFactures[i].remise_ht,
              'tva': newUniteFactures[i].tva,
              'montant_total_ttc': montantTotalTTC
            }
          )
        }
        this.uniteFacturesRender = final_array
      }
    },

    paiements: function (newPaiements) {
      if (newPaiements.length == 0) {
        this.paiementsRender = []
      }
      else {
        var final_array = []
        for (var i = 0; i < newPaiements.length; i++) {
          final_array.push(
            {
              'id': newPaiements[i].id,
              'date': newPaiements[i].date,
              'modalites': newPaiements[i].modalites,
              'montant_ttc': newPaiements[i].montant_ttc,
              'client_name': newPaiements[i].client_name,
            }
          )
        }
        this.paiementsRender = final_array
      }
    },

    onlinePaiements: function (newOnlinePaiements) {
      if (newOnlinePaiements.length == 0) {
        this.onlinePaiementsRender = []
      }
      else {
        var final_array = []
        for (var i = 0; i < newOnlinePaiements.length; i++) {
          final_array.push(
            {
              'id': newOnlinePaiements[i].id,
              'montant': newOnlinePaiements[i].montant,
              'is_paid': newOnlinePaiements[i].is_paid,
              'paiement_date': newOnlinePaiements[i].paiement_date,
              'is_relance': newOnlinePaiements[i].is_relance,
              'relance_frequence': newOnlinePaiements[i].relance_frequence,
              'last_date_relance': newOnlinePaiements[i].last_date_relance,
              'random_url': newOnlinePaiements[i].random_url,
            }
          )
        }
        this.onlinePaiementsRender = final_array
      }
    },
  },


  methods: {
    // ------------- Getters --------------
    getFacture () {
      this.isFactureLoading = true
      apiManagerConnected.getFacture(this.token, this.$route.params.facture_pk)
      .then((result) => {
        this.facture = result.data
        this.modifyFactureTitle = this.facture.facture_title
        this.modifyFactureAddress = this.facture.facturation_address
        this.modifyFactureDelaiPaiement = this.facture.delai_paiement
        this.modifyFactureDateFacturation = this.facture.date_facturation
        this.modifyFactureDateEcheance = this.facture.date_echeance
        this.modifyFactureIsAcompte = String(this.facture.is_acompte)
        this.modifyFactureIsModalitesVirement = this.facture.is_modalites_virement
        this.modifyFactureIsModalitesCb = this.facture.is_modalites_cb
        this.modifyFactureIsModalitesCash = this.facture.is_modalites_cash
        this.modifyFactureIsModalitesCheque = this.facture.is_modalites_cheque
        this.modifyFactureMessageFacture = this.facture.message_facture

        this.sendFactureClientEmail = this.facture.mission.client.email
        this.sendFactureAnswerEmail = this.facture.mission.referent.user.username
      })
      .catch(() => {
        this.$store.commit('openGlobalErrorModal')
      })
      .finally(() => {
        this.isFactureLoading = false
      })
    },

    getAllFactureUnitesFactures () {
      this.isUniteFacturesLoading = true
      apiManagerConnected.getAllFactureUnitesFactures(this.token, this.$route.params.facture_pk)
      .then((result) => {
        this.uniteFactures = result.data
      })
      .catch(() => {
        this.$store.commit('openGlobalErrorModal')
      })
      .finally(() => {
        this.isUniteFacturesLoading = false
      })
    },

    getAllFacturePaiements () {
      this.isPaiementsLoading = true
      apiManagerConnected.getAllFacturePaiements(this.token, this.$route.params.facture_pk)
      .then((result) => {
        this.paiements = result.data
      })
      .catch(() => {
        this.$store.commit('openGlobalErrorModal')
      })
      .finally(() => {
        this.isPaiementsLoading = false
      })
    },

    getAllFactureOnlinePaiements () {
      this.isOnlinePaiementsLoading = true
      apiManagerConnected.getAllFactureOnlinePaiements(this.token, this.$route.params.facture_pk)
      .then((result) => {
        this.onlinePaiements = result.data
      })
      .catch(() => {
        this.$store.commit('openGlobalErrorModal')
      })
      .finally(() => {
        this.isOnlinePaiementsLoading = false
      })
    },

    getAllTasksForFacturation () {
      this.isTasksLoading = true
      apiManagerConnected.getAllTasksForUniteFactureGeneration(
        this.token, this.filterTasksStartDate, this.filterTasksEndDate,
        this.facture.mission.client.id, this.facture.mission.id, this.isNotBilled)
      .then((result) => {
        this.tasks = result.data
        this.isChoosingTaskToGenerateUniteFacture = true
      })
      .catch(() => {
        this.$store.commit('openGlobalErrorModal')
      })
      .finally(() => {
        this.isTasksLoading = false
      })
    },


    downloadFacture () {
      apiManagerConnected.downloadFacture(this.token, this.$route.params.facture_pk)
      .then((res) => {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement('a');
          link.href = url;
          const factureName = 'Facture-' + this.facture.exercice_comptable + '-' + this.facture.facture_number + '-' + this.facture.mission.client.name  + '-' + this.facture.date_facturation + '.pdf'
          link.setAttribute('download', factureName); //or any other extension
          document.body.appendChild(link);
          link.click();
        }, () => {
          this.$store.commit('openGlobalErrorModal')
        })
    },

    downloadPaiement (paiement) {
      apiManagerConnected.downloadPaiement(this.token, paiement.id)
      .then((res) => {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement('a');
          link.href = url;
          const paiementName = 'Recu-' + paiement.date + '-' + paiement.client_name + '.pdf'
          link.setAttribute('download', paiementName); //or any other extension
          document.body.appendChild(link);
          link.click();
        }, () => {
          this.$store.commit('openGlobalErrorModal')
        })
    },


    sendFactureEmail () {
      this.sendFactureEmailInProcess = true
      apiManagerConnected.sendFactureToClient(this.token, this.$route.params.facture_pk, this.sendFactureClientEmail,
        this.sendFactureCustomizedMessage, this.sendFactureAnswerEmail)
      .then(() => {
        this.isFactureSent = true
        if (!this.facture.has_been_sent) {
          this.facture.has_been_sent = true
        }
      })
      .catch(() => {
        this.$store.commit('openGlobalErrorModal')
      })
      .finally(() => {
        this.isSendingFactureByEmail = false
        this.sendFactureEmailInProcess = false
      })
    },



    // ------------ SETTERS -------------

    duplicateFacture () {
      this.duplicateFactureInProcess = true
      this.duplicateFactureButtonText = "Duplication en cours"
      this.duplicateFactureButtonStyle = 'secondary'
      apiManagerConnected.duplicateFacture(this.token, this.$route.params.facture_pk)
      .then((result) => {
        this.$router.push('/modify-facture/' + result.data.id)
        this.getFacture()
        this.getAllFactureUnitesFactures()
        this.getAllFacturePaiements()
      })
      .catch(() => {
        this.$store.commit('openGlobalErrorModal')
      })
      .finally(() => {
        this.isFactureDuplicating = false
        this.duplicateFactureInProcess = false
        this.duplicateFactureButtonText = "Dupliquer cette facture"
        this.duplicateFactureButtonStyle = 'outline-dark'
      })
    },

    modifyFacture () {
      this.modifyFactureInProcess = true
      this.modifyFactureButtonText = "Modification en cours"
      this.modifyFactureButtonStyle = 'secondary'
      apiManagerConnected.modifyFacture(this.token, this.$route.params.facture_pk,
        this.modifyFactureTitle, this.modifyFactureAddress, this.modifyFactureDelaiPaiement,
        this.modifyFactureDateFacturation, this.modifyFactureDateEcheance, this.modifyFactureIsAcompte,
        this.modifyFactureIsModalitesVirement, this.modifyFactureIsModalitesCb,
        this.modifyFactureIsModalitesCash, this.modifyFactureIsModalitesCheque,
        this.modifyFactureMessageFacture)
      .then(() => {
        this.getFacture()
        this.isFactureModified = true
      })
      .catch(() => {
        this.$store.commit('openGlobalErrorModal')
      })
      .finally(() => {
        this.modifyFactureInProcess = false
        this.modifyFactureButtonText = "Modifier cette facture"
        this.modifyFactureButtonStyle = 'outline-primary'
      })
    },
    deleteFacture () {
      this.deleteFactureInProcess = true
      this.deleteFactureButtonText = "Suppression en cours"
      this.deleteFactureButtonStyle = 'secondary'
      apiManagerConnected.deleteFacture(this.token, this.$route.params.facture_pk)
      .then(() => {
        this.getFacture()
        this.isFactureArchived = true
      })
      .catch(() => {
        this.$store.commit('openGlobalErrorModal')
      })
      .finally(() => {
        this.deleteFactureInProcess = false
        this.deleteFactureButtonText = "Archiver cette facture"
        this.deleteFactureButtonStyle = 'outline-danger'
      })
    },

    markFactureAsSent () {
      this.markFactureAsSentInProcess = true
      apiManagerConnected.markFactureAsSent(this.token, this.$route.params.facture_pk)
      .then(() => {
        this.getFacture()
      })
      .catch(() => {
        this.$store.commit('openGlobalErrorModal')
      })
      .finally(() => {
        this.markFactureAsSentInProcess = false
      })
    },

    activateFacture () {
      this.activateFactureInProcess = true
      this.activateFactureButtonText = "Activation en cours"
      this.activateFactureButtonStyle = 'secondary'
      apiManagerConnected.activateFacture(this.token, this.$route.params.facture_pk)
      .then(() => {
        this.getFacture()
      })
      .catch(() => {
        this.$store.commit('openGlobalErrorModal')
      })
      .finally(() => {
        this.activateFactureInProcess = false
        this.activateFactureButtonText = "Réactiver cette facture"
        this.activateFactureButtonStyle = 'outline-primary'
      })
    },

    // --------------- GENERATE UNITE FACTURES FROM TASKS --------
    checkTaskBox (task) {
      /*
        fromTaskUniteFactureDict structure :
        {
          taux_horaire:
            {'hours': total_hours,
             'minutes': total_minutes,
             'descriptions': [description1, description 2],
           },
           XX

        }
      */
      const index = this.chosenTasks.indexOf(task.id)
      //var taux = task.collaborateur_taux_horaire
      //if (this.tauxChosenForUniteFactureFromTask == 'tjm') {
      //  taux= task.collaborateur_taux_horaire*8
      //}
      if (index === -1){
        this.chosenTasks.push(task.id)
        if (task.collaborateur_taux_horaire in this.fromTaskUniteFactureDict) {
          this.fromTaskUniteFactureDict[task.collaborateur_taux_horaire]['hours'] += task.hours
          this.fromTaskUniteFactureDict[task.collaborateur_taux_horaire]['minutes'] += task.minutes
          this.fromTaskUniteFactureDict[task.collaborateur_taux_horaire]['descriptions'].push(task.description)
        }
        else {
          this.fromTaskUniteFactureDict[task.collaborateur_taux_horaire] = {
            'hours': task.hours,
            'minutes': task.minutes,
            'descriptions': [task.description]
          }
        }
      }
      else {
        this.chosenTasks.splice(index, 1)
        this.fromTaskUniteFactureDict[task.collaborateur_taux_horaire]['hours'] -= task.hours
        this.fromTaskUniteFactureDict[task.collaborateur_taux_horaire]['minutes'] -= task.minutes
        this.fromTaskUniteFactureDict[task.collaborateur_taux_horaire]['descriptions'].splice(index, 1)
        if (this.fromTaskUniteFactureDict[task.collaborateur_taux_horaire]['hours'] == 0 && this.fromTaskUniteFactureDict[task.collaborateur_taux_horaire]['minutes'] == 0) {
          delete this.fromTaskUniteFactureDict[task.collaborateur_taux_horaire]
        }
      }
    },

    markTasksAsBilled () {
      this.isMarkingTasksAsBilled = true
      apiManagerConnected.markTasksAsBilled(this.token, this.chosenTasks)
      .then(() => {
        this.chosenTasks = []
        this.tasks = []
        this.isContenuCreatedFromTasks = true
      })
      .catch(() => {
        this.$store.commit('openGlobalErrorModal')
      })
      .finally(() => {
        this.isMarkingTasksAsBilled = false
        this.isChoosingTaskToGenerateUniteFacture = false
      })

    },


    // --------------- UNITE FACTURE ---------------------
    addUniteFacture (date, description, quantite, prix_unitaire, remise_ht, tva) {
      this.addUniteFacturesInProcess = true
      this.addUniteFacturesButtonText = "En cours"
      this.addUniteFacturesButtonStyle = 'secondary'
      apiManagerConnected.addUniteFacture(this.token,
        this.$route.params.facture_pk, date, description, quantite, prix_unitaire, remise_ht, tva)
      .then(() => {
        this.getAllFactureUnitesFactures()

        this.newUniteFactures.description = ''
        this.isUniteFacturesAddingBigDescription = false
      })
      .catch(() => {
        this.$store.commit('openGlobalErrorModal')
      })
      .finally(() => {
        this.addUniteFacturesInProcess = false
        this.addUniteFacturesButtonText = "Ajouter"
        this.addUniteFacturesButtonStyle = 'dark'
      })
    },

    addMultipleUnitesFactureFromTasks () {
      this.isAddingMultipleUnitesFacture = true
      const today = this.$dayjs().format('YYYY-MM-DD')
      var unitesFacture = []
      for (var tauxHoraire in this.fromTaskUniteFactureDict) {
        var quantite = this.timeToDecimal(this.fromTaskUniteFactureDict[tauxHoraire].hours, this.fromTaskUniteFactureDict[tauxHoraire].minutes)
        if (this.tauxChosenForUniteFactureFromTask == 'tjm') {
          quantite = parseFloat(this.timeToDecimal(this.fromTaskUniteFactureDict[tauxHoraire].hours, this.fromTaskUniteFactureDict[tauxHoraire].minutes)/8).toFixed(2)
        }
        var taux = tauxHoraire
        if (this.tauxChosenForUniteFactureFromTask == 'tjm') {
          taux = tauxHoraire*8
        }
        unitesFacture.push(
          {
            'facture': this.$route.params.facture_pk,
            'date': today,
            'description': this.fromTaskUniteFactureDict[tauxHoraire].descriptions.join("\n"),
            'quantite': quantite,
            'prix_unitaire': taux,
            'remise_ht': 0,
            'tva': 20,
          }
        )
      }
      apiManagerConnected.addMultipleUnitesFacture(this.token, unitesFacture)
      .then(() => {
        this.getAllFactureUnitesFactures()
        this.fromTaskUniteFactureDict = {}
        this.markTasksAsBilled()
      })
      .catch(() => {
        this.$store.commit('openGlobalErrorModal')
      })
      .finally(() => {
        this.isAddingMultipleUnitesFacture = false
      })
    },

    showModifyUniteFactures(item) {
      this.modifyUniteFacturesId = item.id,
      this.modifyUniteFacturesDate = item.date,
      this.modifyUniteFacturesDescription = item.description,
      this.modifyUniteFacturesQuantite = item.quantite,
      this.modifyUniteFacturesPrixUnitaire = item.prix_unitaire,
      this.modifyUniteFacturesRemiseHT = item.remise_ht,
      this.modifyUniteFacturesTVA = item.tva,

      this.isUniteFacturesModifying = true
    },
    modifyUniteFactures () {
      this.modifyUniteFacturesButtonText = 'Modification en cours'
      this.modifyUniteFacturesButtonStyle = 'secondary'
      this.modifyUniteFacturesInProcess = true
      apiManagerConnected.modifyUniteFacture(this.token,
        this.modifyUniteFacturesId, this.modifyUniteFacturesDate, this.modifyUniteFacturesDescription, this.modifyUniteFacturesQuantite, this.modifyUniteFacturesPrixUnitaire,
        this.modifyUniteFacturesRemiseHT, this.modifyUniteFacturesTVA)
      .then(() => {
        this.getAllFactureUnitesFactures()
      })
      .catch(() => {
        this.$store.commit('openGlobalErrorModal')
      })
      .finally(() => {
        this.isUniteFacturesModifying = false
        this.modifyUniteFacturesButtonText = 'Modifier'
        this.modifyUniteFacturesButtonStyle = 'outline-primary'
        this.modifyUniteFacturesInProcess = false
      })
    },
    deleteUniteFactures (unite_facture_id) {
      this.deleteUniteFacturesButtonText = 'Suppression en cours'
      this.deleteUniteFacturesButtonStyle = 'secondary'
      this.deleteUniteFacturesInProcess = true
      apiManagerConnected.deleteUniteFacture(this.token, unite_facture_id)
      .then(() => {
        this.getAllFactureUnitesFactures()
      })
      .catch(() => {
        this.$store.commit('openGlobalErrorModal')
      })
      .finally(() => {
        this.deleteUniteFacturesButtonText = 'Supprimer'
        this.deleteUniteFacturesButtonStyle = 'outline-danger'
        this.deleteUniteFacturesInProcess = false
      })
    },


    // --------------- PAIEMENTS ---------------------
    addPaiement (date, modalites, montant_ttc) {
      this.addPaiementInProcess = true
      this.addPaiementButtonText = "En cours"
      this.addPaiementButtonStyle = 'secondary'
      apiManagerConnected.addPaiement(this.token,
        this.$route.params.facture_pk, date, modalites, montant_ttc)
      .then(() => {
        this.getAllFacturePaiements()

        this.newPaiement.montant_ttc = ''
      })
      .catch(() => {
        this.$store.commit('openGlobalErrorModal')
      })
      .finally(() => {
        this.addPaiementInProcess = false
        this.addPaiementButtonText = "Ajouter"
        this.addPaiementButtonStyle = 'outline-primary'
      })
    },

    showModifyPaiement(item) {
      this.modifyPaiementId = item.id,
      this.modifyPaiementDate = item.date,
      this.modifyPaiementModalites = item.modalites,
      this.modifyPaiementMontantTTC = item.montant_ttc,

      this.isPaiementModifying = true
    },
    modifyPaiement () {
      this.modifyPaiementButtonText = 'Modification en cours'
      this.modifyPaiementButtonStyle = 'secondary'
      this.modifyPaiementInProcess = true
      apiManagerConnected.modifyPaiement(this.token,
        this.modifyPaiementId, this.modifyPaiementDate, this.modifyPaiementModalites, this.modifyPaiementMontantTTC)
      .then(() => {
        this.getAllFacturePaiements()
      })
      .catch(() => {
        this.$store.commit('openGlobalErrorModal')
      })
      .finally(() => {
        this.isPaiementModifying = false
        this.modifyPaiementButtonText = 'Modifier'
        this.modifyPaiementButtonStyle = 'outline-primary'
        this.modifyPaiementInProcess = false
      })
    },
    deletePaiement (paiement_id) {
      this.deletePaiementButtonText = 'Suppression en cours'
      this.deletePaiementButtonStyle = 'secondary'
      this.deletePaiementInProcess = true
      apiManagerConnected.deletePaiement(this.token, paiement_id)
      .then(() => {
        this.getAllFacturePaiements()
      })
      .catch(() => {
        this.$store.commit('openGlobalErrorModal')
      })
      .finally(() => {
        this.deletePaiementButtonText = 'Supprimer'
        this.deletePaiementButtonStyle = 'outline-danger'
        this.deletePaiementInProcess = false
      })
    },



    // --------------- ONLINE PAIEMENTS ---------------------
    addOnlinePaiement () {
      this.addOnlinePaiementInProcess = true
      apiManagerConnected.addOnlinePaiement(this.token,
        this.$route.params.facture_pk, this.newOnlinePaiement.montant, this.newOnlinePaiement.is_relance, this.newOnlinePaiement.relance_frequence)
      .then((result) => {
        this.getAllFactureOnlinePaiements()
        this.createdOnlinePaiement = result.data
        this.newOnlinePaiement.montant = ''
        this.newOnlinePaiement.is_relance = false
        this.newOnlinePaiement.relance_frequence = 'WEEK'

        this.isOnlinePaiementAdded = true
      })
      .catch(() => {
        this.$store.commit('openGlobalErrorModal')
      })
      .finally(() => {
        this.isOnlinePaiementAdding = false
        this.addOnlinePaiementInProcess = false
      })
    },

    deleteOnlinePaiement (pk) {
      this.addOnlinePaiementInProcess = true
      apiManagerConnected.deleteOnlinePaiement(this.token, pk)
      .then(() => {
        this.getAllFactureOnlinePaiements()

        this.isOnlinePaiementDeleted= true
      })
      .catch(() => {
        this.$store.commit('openGlobalErrorModal')
      })
      .finally(() => {
        this.addOnlinePaiementInProcess = false
      })
    },

    copyCodeToClipBoard (textToCopy) {
      const el = document.createElement('textarea');
      el.value = textToCopy;
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
      this.copiedToasts ++
    },
  }
}
</script>

<style>
.no-border-table td {
  border-top: None;
}

.no-border-table th {
  border-top: None;
  border-bottom: None !important;
}

.no-margin-bottom-table {
  margin-bottom: 0px !important;
}

.toast {
  flex-basis: auto !important;
}
.custom-toast {
  background-color: white;
}

</style>
