var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"factureViewFraisComponent"},[_c('loading',{attrs:{"active":_vm.isLoading,"can-cancel":false,"is-full-page":true,"color":"black"},on:{"update:active":function($event){_vm.isLoading=$event}}}),_c('CRow',{staticClass:"mt-3"},[_c('CCol',[_c('CDataTable',{attrs:{"headVariant":"light","items":_vm.fraisRender,"fields":_vm.fraisRenderFields,"noItemsView":{ noResults: "Aucun frais", noItems: "Aucun frais" }},scopedSlots:_vm._u([{key:"date",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.$dayjs(item.date).format('DD/MM/YYYY'))+" ")])]}},{key:"description",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('small',[_vm._v(_vm._s(item.description))])])]}},{key:"montant",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.formatThousandSeparatorNumber(parseFloat(item.montant).toFixed(2)))+" € ")])]}},{key:"actions",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('CButton',{staticClass:"ml-2",attrs:{"disabled":!item.file,"square":"","size":"sm"},on:{"click":function($event){return _vm.downloadFraisFile(item.id, item.file_name)}}},[(item.file)?_c('CIcon',{staticClass:"text-dark",attrs:{"name":"cil-cloud-download"}}):_c('CIcon',{staticClass:"text-white",attrs:{"name":"cil-cloud-download"}})],1)],1)]}},{key:"no-items-view",fn:function(){return [_c('CRow',[_c('CCol',{staticClass:"text-center"},[_vm._v(" Aucun frais associé à cette mission ")])],1)]},proxy:true}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }