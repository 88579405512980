<template>
  <div class="factureViewFraisComponent">

    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="true"
      color="black">
    </loading>


    <CRow class="mt-3">
      <CCol>
        <CDataTable
          headVariant="light"
          :items="fraisRender"
          :fields="fraisRenderFields"
          :noItemsView='{ noResults: "Aucun frais", noItems: "Aucun frais" }'
        >

        <template #date="{item}">
          <td class="text-center">
           {{$dayjs(item.date).format('DD/MM/YYYY')}}
          </td>
        </template>

        <template #description="{item}">
          <td>
           <small>{{item.description}}</small>
          </td>
        </template>

        <template #montant="{item}">
          <td class="text-center">
            {{formatThousandSeparatorNumber(parseFloat(item.montant).toFixed(2))}} €
          </td>
        </template>

        <template #actions="{item}">
          <td>
            <CButton
              :disabled="!item.file"
              class="ml-2"
              square
              size="sm"
              @click="downloadFraisFile(item.id, item.file_name)">
              <CIcon v-if="item.file" class="text-dark" name="cil-cloud-download"/>
              <CIcon v-else class="text-white" name="cil-cloud-download"/>
            </CButton>
          </td>
        </template>

        <template #no-items-view>
          <CRow>
            <CCol class="text-center">
              Aucun frais associé à cette mission
            </CCol>
          </CRow>
        </template>
        </CDataTable>

      </CCol>
    </CRow>


  </div>
</template>

<script>
import { APIUserConnected } from '@/api/APIUserConnected'

import renderMixins from '@/mixins/renderMixins'

import Loading from 'vue-loading-overlay';

import 'vue-loading-overlay/dist/vue-loading.css';

const apiUserConnected = new APIUserConnected()

export default {
  name: 'factureViewFraisComponent',
  components: {
    Loading,
  },
  mixins: [
    renderMixins
  ],
  props: {
    missionPk: String,
  },
  data: function () {
    return {

      // ------------- FRAIS -----------
      frais: [],
      fraisRender: [],
      fraisRenderFields: [
        { key: "date", label: "Date", _style: "min-width: 200px;"},
        { key: "description", label: "Description", _style: "min-width: 200px;"},
        { key: "montant", label: "Montant", _style: "min-width: 150px;"},
        { key: "collaborateur_full_name", label: "Ajouté par", _style: "min-width: 150px;"},
        { key: "actions", label: "Actions", _style: "min-width: 100px;"},
      ],
      isAllFraisLoading: false,
    }
  },
  computed: {
    token () {
      return this.$store.state.auth.token
    },
    isLoading () {
      if (this.isAllFraisLoading) {
        return true
      }
      return false
    },

  },

  created: function() {
    this.$dayjs.locale('fr')
    this.getMissionFrais()
  },

  watch: {
    frais: function (resultFrais) {
      if (resultFrais.length == 0) {
        this.fraisRender = []
      }
      else {
        var final_array = []
        for (var i = 0; i < resultFrais.length; i++) {
          final_array.push(
            {
              'id': resultFrais[i].id,
              'mission_id': resultFrais[i].mission,
              'mission_name': resultFrais[i].mission_name,
              'date': resultFrais[i].date,
              'montant': resultFrais[i].montant,
              'description': resultFrais[i].description,
              'collaborateur_full_name': resultFrais[i].collaborateur_full_name,
              'file_name': resultFrais[i].file_name,
              'file': resultFrais[i].file
            }
          )
        }
        this.fraisRender = final_array
      }
    },
  },

  methods: {

    // -------------- GETTERS -----------------------

    getMissionFrais () {
      this.isAllFraisLoading = true
      apiUserConnected.getMissionFrais(this.token, this.missionPk)
      .then((result) => {
        this.frais = result.data
      })
      .catch(() => {
        this.$store.commit('openGlobalErrorModal')
      })
      .finally(() => {
        this.isAllFraisLoading = false
      })
    },

    downloadFraisFile (document_id, document_name) {
      apiUserConnected.downloadFraisFile(this.token, document_id)
      .then((res) => {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', document_name); //or any other extension
          document.body.appendChild(link);
          link.click();
        }, () => {
          this.$store.commit('openGlobalErrorModal')
        })
    },
  }



}
</script>

<style>
.no-border-table td {
  border-top: None;
}

.no-border-table th {
  border-top: None;
  border-bottom: None !important;
}

.no-margin-bottom-table {
  margin-bottom: 0px !important;
}
</style>
